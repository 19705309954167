import CryptoJS from 'crypto-js';
import { env } from 'env';
import { IPageModelBase } from 'models/base-type';
import { ISettingModel } from 'models/settings';
import { ORDER_BY_ASC, ORDER_BY_DESC } from 'shared/constants/pagination';
import { ApplicationModule } from 'shared/enums/application-module';
import { Digits } from 'shared/enums/digits';
import { PageActions } from 'shared/enums/table-page-actions';

const FILE_SIZE = 5000000;

export const stringIsNumber = (value) => isNaN(Number(value)) === false;

// Turn enum into array
export const enumToArray = (enumeration) => {
    return Object.keys(enumeration)
        .filter(stringIsNumber)
        .map((key) => enumeration[key]);
};

export const replaceAll = (str, oldChar, newChar) => {
    const regex = new RegExp(`${oldChar}`, 'g');
    return str?.replace(regex, newChar);
};

export const handleCheckboxClick = (event, id, selected) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    return newSelected;
};

export function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export const stringAvatar = (name: string) => {
    const showName =
        name?.split(' ').length == 1
            ? `${name?.split(' ')[0][0]}${name?.split(' ')[0][1]}`
            : `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`;
    return showName;
};

export const getDecryptKey = (value: string) => {
    return CryptoJS.AES.decrypt(value, env.REACT_APP_CIPHER).toString(CryptoJS.enc.Utf8);
};

export const getEncryptKey = (value: string) => {
    return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), env.REACT_APP_CIPHER).toString();
};

export const getBaseURL = (url: string) => {
    return url + '/api/';
};

export const toFixed = (value: number, decimals = 2) => {
    value = Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
    return value?.toFixed(decimals);
};

// array2 is object of array
export const isSameArray = (array1, array2, key: string) => {
    let i,
        isSameArray = false;
    for (i = 0; i < array2?.length; i++) {
        if (array1.includes(array2[i]?.[key])) {
            isSameArray = true;
        } else {
            isSameArray = false;
            break;
        }
    }
    return isSameArray;
};

// merge isd code and number
export const getMobileNumber = (isdCode: string, mobileNo: string) => {
    return isdCode ? isdCode + ' ' + mobileNo : mobileNo;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlePageDetailChange = (action: any, value: any, pageDetails: IPageModelBase) => {
    let pageDetail: IPageModelBase = pageDetails;
    switch (action) {
        case PageActions.OrderDetailChange: {
            const isAsc = pageDetail.orderBy === value && pageDetail.order === ORDER_BY_ASC;
            pageDetail = {
                ...pageDetail,
                order: isAsc ? ORDER_BY_DESC : ORDER_BY_ASC,
                orderBy: value,
            };
            break;
        }
        case PageActions.PageChange: {
            pageDetail = {
                ...pageDetail,
                page: value,
            };
            break;
        }
        case PageActions.RowsPerPageChange: {
            pageDetail = {
                ...pageDetail,
                page: Digits.One,
                rowsPerPage: value,
            };
            break;
        }
    }
    return pageDetail;
};

export const isSelected = (id, selected) => selected.indexOf(id) !== -1;

export const getSettingValue = (settings: Array<ISettingModel>, settingName: string) => {
    const settingValue = settings?.find((s) => s.settingName === settingName)?.settingValue;
    return settingValue ? settingValue : '-';
};

export const isValidFileType = (file: Blob, supportedFormats: Array<string>) => {
    return supportedFormats.includes(file?.type);
};

export const isValidFileSize = (file: Blob) => {
    return file?.size <= FILE_SIZE; // 1 Mb
};

export const getFileName = (file) => {
    return file?.name.substr(0, file?.name.lastIndexOf('.'))?.trim();
};

export const isPublicPage = (page: string) => {
    return [
        ApplicationModule.Useful_Links_and_Resources.toString(),
        ApplicationModule.Contract_Providers.toString(),
        ApplicationModule.Members.toString(),
    ].includes(page);
};

export const showLoader = () => {
    const loaderDiv = document.getElementById('loader');

    if (loaderDiv) {
        loaderDiv.style.display = 'flex';
        loaderDiv.style.visibility = 'visible';
    }
};

export const hideLoader = () => {
    const loaderDiv = document.getElementById('loader');

    if (loaderDiv) {
        loaderDiv.style.display = 'none';
        loaderDiv.style.visibility = 'hidden';
    }
};
