import { combineReducers } from 'redux';
import AuthReducer from './auth-reducer';
import { GeneralReducer } from './general-reducer';

const rootReducer = combineReducers({
    AuthData: AuthReducer,
    GeneralData: GeneralReducer,
});

export default rootReducer;
