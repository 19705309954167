import { createTheme } from '@mui/material/styles';
const customTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1299,
        },
    },
});

const appTheme = createTheme(customTheme, {
    palette: {
        primary: {
            main: '#4182E2',
            contrastText: '#fff',
        },
        secondary: {
            contrastText: '#fff',
            main: '#4182E2',
        },
        text: {
            primary: '#454545',
            disabled: '#A3A3A3',
        },
        info: {
            main: '#9BB6D5',
            contrastText: '#6AB7FF',
        },
        error: {
            main: '#FF5353',
            light: '#FF8A8A',
        },
        warning: {
            main: '#FDA53D',
            light: '#F8824F',
        },
        success: {
            main: '#45D09E',
        },
    },
    typography: {
        fontFamily: "'Poppins', sans-serif",
        h1: {
            fontSize: '3.5rem',
            fontWeight: 600,
            lineHeight: '5rem',
            color: '#262626',
            fontFamily: '"Poppins", sans-serif',
            letterSpacing: 0,
            [customTheme.breakpoints.up('sm')]: {
                fontSize: '4rem',
            },
            [customTheme.breakpoints.up('md')]: {
                fontSize: '5.rem',
                lineHeight: '6.2rem',
            },
            [customTheme.breakpoints.up('lg')]: {
                fontSize: '5rem',
                lineHeight: '6rem',
            },
            [customTheme.breakpoints.up('xl')]: {
                fontSize: '7rem',
                lineHeight: '9rem',
            },
        },
        h2: {
            fontSize: '1.8rem',
            fontWeight: 600,
            lineHeight: '2.4rem',
            color: '#262626',
            fontFamily: '"Poppins", sans-serif',
            letterSpacing: 0,
            [customTheme.breakpoints.up('md')]: {
                fontSize: '2rem',
                lineHeight: '3rem',
            },
            [customTheme.breakpoints.up('lg')]: {
                fontSize: '2.4rem',
                lineHeight: '3.6rem',
            },
            '&.font-light': {
                fontWeight: 500,
            },
        },
        h3: {
            fontSize: '1.6rem',
            fontWeight: 600,
            lineHeight: '2.5rem',
            color: '#262626',
            fontFamily: '"Poppins", sans-serif',
            letterSpacing: 0,
            [customTheme.breakpoints.up('md')]: {
                fontSize: '2rem',
                lineHeight: '3rem',
            },
        },
        h4: {
            fontSize: '1.6rem',
            fontWeight: 500,
            lineHeight: '2.6rem',
            color: '#262626',
            fontFamily: '"Poppins", sans-serif',
            letterSpacing: 0,
            [customTheme.breakpoints.up('md')]: {
                fontSize: '1.8rem',
                lineHeight: '2.8rem',
            },
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    '&.primary-card': {
                        boxShadow: 'none',
                        borderRadius: '1.2rem',
                        fontFamily: "'Poppins', sans-serif",
                        height: '100%',
                        '.MuiCardContent-root': {
                            padding: '2rem 1rem',
                            '&:last-child': {
                                paddingBottom: '2rem',
                            },
                            [customTheme.breakpoints.up('lg')]: {
                                padding: '2rem 1.5rem',
                            },
                        },
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    padding: '1rem 1.3rem',
                    borderRadius: '0.6rem',
                    fontSize: '1.4rem',
                    lineHeight: '2rem',
                    fontWeight: 500,
                    textTransform: 'capitalize',
                    boxShadow: 'none',
                    transition: 'all 0.5s',
                    letterSpacing: 0,
                    fontFamily: "'Poppins', sans-serif",
                    [customTheme.breakpoints.up('sm')]: {
                        padding: '1rem 1.8rem',
                        fontSize: '1.6rem',
                        lineHeight: '2.4rem',
                    },
                    [customTheme.breakpoints.up('md')]: {
                        fontSize: '1.8rem',
                        lineHeight: '2.8rem',
                    },
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: '#004cbe',
                        color: '#fff',
                    },
                    '&.MuiButton-startIcon': {
                        marginRight: '0.8rem',
                    },
                    '&.user-menu-button': {
                        padding: 0,
                        width: '4rem',
                        height: '4rem',
                        borderRadius: '50%',
                        textTransform: 'uppercase',
                        fontSize: '1.4rem',
                        lineHeight: '2.1rem',
                        minWidth: '4rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#B5B5B5',
                        color: '#fff',
                    },
                },
                outlinedPrimary: {
                    border: '0.1rem solid #4182E2',
                    color: '#4182E2',
                    padding: '0.9rem 1.3rem',
                    borderRadius: '0.6rem',
                    fontSize: '1.4rem',
                    lineHeight: '2rem',
                    fontWeight: 500,
                    textTransform: 'capitalize',
                    boxShadow: 'none',
                    transition: 'all 0.5s',
                    letterSpacing: 0,
                    fontFamily: "'Poppins', sans-serif",
                    '&:hover': {
                        boxShadow: 'none',
                        color: '#4182E2',
                    },
                    [customTheme.breakpoints.up('sm')]: {
                        padding: '0.9rem 2rem',
                        fontSize: '1.6rem',
                        lineHeight: '2.4rem',
                    },
                    [customTheme.breakpoints.up('md')]: {
                        fontSize: '1.8rem',
                        lineHeight: '2.8rem',
                    },
                    '&.Mui-disabled': {
                        borderColor: '#B5B5B5',
                        color: '#B5B5B5',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    transform: 'translate(1.2rem, 1.2rem) scale(1)',
                    fontSize: '1.4rem',
                    fontWeight: 400,
                    color: '#757575',
                    transition: 'all 0.5s',
                    letterSpacing: 0,
                    fontFamily: "'Poppins', sans-serif",
                    '&.Mui-focused': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#4182E2',
                            borderWidth: '0.1rem',
                        },
                    },
                    '&.MuiInputLabel-shrink': {
                        transform: 'translate(1.65rem, -0.7rem) scale(0.9)',
                        color: '#4182E2',
                        fontSize: '1.2rem',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    paddingRight: '1.2rem',
                    borderRadius: '0.8rem',
                    fontFamily: "'Poppins', sans-serif",
                    '&:hover': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#4182E2',
                        },
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#F9F9F9',
                        borderColor: '#D7D7D7',
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#D7D7D7',
                        },
                    },
                },
                notchedOutline: {
                    borderColor: '#D7D7D7',
                    fontFamily: "'Poppins', sans-serif",
                    legend: {
                        fontSize: '1.2rem',
                    },
                },
                input: {
                    padding: '1.4rem 1.2rem',
                    letterSpacing: 0,
                    fontSize: '1.4rem',
                    fontWeight: 400,
                    fontFamily: "'Poppins', sans-serif",
                    height: 'auto',
                    lineHeight: '2rem',
                    '&.MuiSelect-outlined': {
                        padding: '1.4rem 1.2rem',
                        minHeight: 'inherit',
                        fontSize: '1.4rem',
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                outlined: {
                    '.MuiIconButton-root': {
                        padding: '0.8rem 1rem 0.8rem 0.8rem',
                        marginRight: '-0.5rem !important',
                        fontFamily: "'Poppins', sans-serif",
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginRight: 0,
                    marginLeft: '-0.9rem',
                },
                label: {
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    color: '#262626',
                    letterSpacing: 0,
                    fontFamily: "'Poppins', sans-serif",
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '.MuiSvgIcon-root': {
                        width: '1.6rem',
                        height: '2.4rem',
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    '&.user-menu-outer': {
                        '.MuiMenu-paper': {
                            width: '21.1rem',
                        },
                        '.MuiList-padding': {
                            padding: '1rem 1.1rem 1.6rem 1.1rem',
                        },
                        '.MuiButtonBase-root': {
                            padding: 0,
                            fontSize: '1.6rem',
                            fontWeight: 400,
                            lineHeight: '2.4rem',
                            color: '#262626',
                            marginBottom: '1.2rem',
                            transition: 'all 0.5s',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                opacity: '0.6',
                            },
                            '&:last-child': {
                                margin: 0,
                            },
                        },
                        '.MuiListItemIcon-root': {
                            minWidth: '2.4rem',
                            marginRight: '0.4rem',
                        },
                        '.MuiTouchRipple-root': {
                            display: 'none',
                        },
                    },
                },
                paper: {
                    boxShadow: '0 0 2.2rem 0.4rem rgba(0,0,0,0.12)',
                    borderRadius: '1rem',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    zIndex: '1302',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    minHeight: '3.3rem',
                    maxWidth: '100%',
                    textTransform: 'none',
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: '1.3rem',
                    fontWeight: 600,
                    lineHeight: '2rem',
                    letterSpacing: 0,
                    color: '#262626',
                    padding: 0,
                    marginRight: '1.5rem',
                    transition: 'all 0.5s',
                    [customTheme.breakpoints.up('lg')]: {
                        lineHeight: '3.2rem',
                        fontSize: '1.6rem',
                        marginRight: '2.3rem',
                        minWidth: '7.7rem',
                    },
                    '&:last-child': {
                        margin: 0,
                    },
                    '&:hover': {
                        color: '#4182E2',
                    },
                },
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                label: {
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: '1.6rem',
                    lineHeight: '2.2rem',
                    color: '#262626',
                    fontWeight: 600,
                    letterSpacing: 0,
                    margin: 0,
                },
                switchViewButton: {
                    '.MuiSvgIcon-root': {
                        width: '2rem',
                        height: '2rem',
                        fontSize: '2rem',
                    },
                },
            },
        },
        MuiPickersArrowSwitcher: {
            styleOverrides: {
                root: {
                    '.MuiIconButton-root': {
                        '.MuiSvgIcon-root': {
                            width: '2rem',
                            height: '2rem',
                            fontSize: '2rem',
                        },
                    },
                },
            },
        },
        MuiDayCalendar: {
            styleOverrides: {
                header: {
                    '.MuiDayCalendar-weekDayLabel': {
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: '1.4rem',
                        lineHeight: '2.2rem',
                        color: '#262626',
                        fontWeight: 600,
                        letterSpacing: 0,
                        width: '4rem',
                    },
                },
                monthContainer: {
                    '.MuiPickersDay-dayWithMargin': {
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: '1.4rem',
                        lineHeight: '2.2rem',
                        color: '#262626',
                        fontWeight: 400,
                        letterSpacing: 0,
                        width: '4rem',
                        height: '4rem',
                        '&.MuiPickersDay-today': {
                            borderColor: '#4182E2',
                            backgroundColor: '#4182E2',
                            color: '#fff',
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(65, 130, 226, 0.08)',
                        },
                    },
                    '.MuiPickersDay-hiddenDaySpacingFiller': {
                        opacity: 1,
                        color: '#D7D7D7',
                    },
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    whiteSpace: 'nowrap',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem',
                    fontFamily: "'Poppins', sans-serif",
                    lineHeight: '1.8rem',
                    borderRight: '0.1rem solid #D7D7D7',
                    borderBottom: 0,
                    padding: '1rem 2rem',
                    letterSpacing: 0,
                    color: '#262626',
                    verticalAlign: 'middle',
                    '&:last-child': {
                        borderRight: 0,
                        textAlign: 'center',
                    },
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                text: {
                    fontSize: '1.4rem',
                    fontFamily: "'Poppins', sans-serif",
                    lineHeight: '1.8rem',
                    letterSpacing: 0,
                    color: '#757575',
                    width: '2.5rem',
                    height: '2.5rem',
                    minWidth: '2.5rem',
                    borderRadius: '0.6rem',
                    margin: '0 0.3rem 0 0',
                    padding: 0,
                    transition: 'all 0.5s',
                    [customTheme.breakpoints.up('md')]: {
                        width: '4rem',
                        height: '4rem',
                        minWidth: '4rem',
                        margin: '0 0.6rem 0 0',
                    },
                    '.MuiSvgIcon-root': {
                        width: '2.2rem',
                        height: '2.2rem',
                        fontSize: '2.2rem',
                    },
                    '&.Mui-selected': {
                        backgroundColor: 'transparent',
                        border: '0.1rem solid #4182E2',
                        color: '#4182E2',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(65, 130, 226, 0.08)',
                    },
                },
                ellipsis: {
                    height: 'auto',
                    [customTheme.breakpoints.up('md')]: {
                        height: '2rem',
                    },
                },
                previousNext: {
                    width: '3rem',
                    height: '3rem',
                    minWidth: '3rem',
                    margin: '0 0.9rem 0 0',
                },
            },
        },
        MuiTabScrollButton: {
            styleOverrides: {
                root: {
                    '.MuiSvgIcon-root': {
                        width: '2rem',
                        height: '2rem',
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem',
                    fontWeight: 400,
                    color: '#262626',
                    fontFamily: "'Poppins', sans-serif",
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                    marginLeft: '14px',
                },
            },
        },
        MuiPickersYear: {
            styleOverrides: {
                yearButton: {
                    fontSize: '1.2rem',
                },
            },
        },
        MuiPickersMonth: {
            styleOverrides: {
                monthButton: {
                    fontSize: '1.2rem',
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                icon: {
                    opacity: 1,
                    fill: '#d1d1d1',
                },
                root: {
                    '&.Mui-active .MuiTableSortLabel-icon': {
                        fill: [customTheme.palette.text.primary] + '!important',
                    },
                },
            },
        },
    },
});

export default appTheme;
