import { configureStore, Tuple } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import { loadStorage, saveStorage } from 'shared/utils/localStorage';
import rootReducer from './reducers/root-reducer';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import { IApplicationState } from './state/app-state';

const persistentState: IApplicationState = loadStorage();
const stateSyncMiddleware = createStateSyncMiddleware({ broadcastChannelOption: { type: 'localstorage' } });

const store = configureStore({
    reducer: rootReducer,
    preloadedState: persistentState,
    middleware: () => new Tuple(thunk, stateSyncMiddleware),
});

initMessageListener(store);

store.subscribe(() => {
    saveStorage(store.getState());
});

export default store;
