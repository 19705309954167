import React from 'react';
import _ from 'lodash';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import images from 'assets/images';

const FormikCheckBox = ({ field: { ...fields }, form: { touched, errors }, ...props }): React.ReactNode => {
    const { label, className, ...rest } = props;
    const error = Boolean(_.get(touched, fields?.name) && _.get(errors, fields?.name));

    return (
        <FormControl error={error} disabled={props?.disabled}>
            <FormGroup>
                <FormControlLabel
                    className={`check ${className}`}
                    label={label}
                    control={
                        <Checkbox
                            {...fields}
                            {...rest}
                            icon={<img src={images.checkboxUnchecked} />}
                            checkedIcon={<img src={images.checkboxChecked} />}
                            checked={fields?.value}
                        />
                    }
                />
            </FormGroup>
            {error && <FormHelperText error>{error && errors[fields?.name]}</FormHelperText>}
        </FormControl>
    );
};

export default FormikCheckBox;
