import { Avatar, Breakpoint, Dialog, DialogTitle, IconButton, PaperProps, Typography } from '@mui/material';
import images from 'assets/images';
import React from 'react';

interface IDialogFormProps {
    scroll?: 'body' | 'paper';
    maxWidth?: Breakpoint | false;
    openDialog?: boolean;
    title?: string;
    className?: string;
    bodyContent?: React.ReactNode;
    paperProps?: Partial<PaperProps>;
    disableEnforceFocus?: boolean;
    titleClassName?: string;
    handleDialogClose?: () => void;
}

const DialogForm: React.FC<IDialogFormProps> = ({
    scroll,
    maxWidth,
    openDialog,
    handleDialogClose,
    title,
    className,
    bodyContent,
    disableEnforceFocus,
    paperProps,
    titleClassName,
}) => {
    return (
        <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            // onClose={(event, reason) => {
            //     if (reason !== 'backdropClick') {
            //         handleDialogClose;
            //     }
            // }}
            className={`${className}`}
            scroll={scroll}
            maxWidth={maxWidth}
            PaperProps={paperProps}
            disableEnforceFocus={disableEnforceFocus}
        >
            {title && (
                <DialogTitle className={titleClassName}>
                    <Typography className="text-collapse">{title}</Typography>
                    <IconButton size="small" className="close-action" onClick={handleDialogClose}>
                        <Avatar sx={{ width: 22, height: 22 }} src={images.close} />
                    </IconButton>
                </DialogTitle>
            )}
            {bodyContent}
        </Dialog>
    );
};

export default DialogForm;
