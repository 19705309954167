import { Typography, DialogContent, InputAdornment, IconButton, Button } from '@mui/material';
import images from 'assets/images';
import { Formik, Field } from 'formik';
import { ILoginModel } from 'models/account';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormikCheckBox, FormikInput } from 'shared/material-ui-formik';
import { LoginValidationSchema } from 'validation/account/account-validation';
import { IApplicationState } from 'store/state/app-state';
import { Link } from 'react-router-dom';

interface ILoginFormProps {
    handleLogin: (data: ILoginModel) => void;
    handleForgotPasswordClick: () => void;
}

const LoginForm: React.FC<ILoginFormProps> = ({ handleLogin, handleForgotPasswordClick }) => {
    const userData = useSelector((state: IApplicationState) => state?.AuthData);

    const [showPassword, setShowPassword] = React.useState(false);

    const initialState: ILoginModel = {
        userName: userData?.userEmail || '',
        password: userData?.password || '',
        rememberMe: !!userData?.userEmail && !!userData?.password,
    };

    const handleShowPassword = () => setShowPassword((show) => !show);

    const mouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <>
            <div className="MuiDialogTitle-root">
                <Typography variant="h2">Internal Login</Typography>
                <span>Please sign in to your account</span>
            </div>
            <DialogContent>
                <Formik
                    initialValues={initialState}
                    onSubmit={handleLogin}
                    validationSchema={LoginValidationSchema}
                    validateOnBlur={false}
                    validateOnChange={true}
                >
                    {(props) => {
                        const { handleSubmit } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="form-label">Username</label>
                                    <Field name="userName" id="userName" type="text" component={FormikInput} />
                                </div>
                                <div className="form-group mb-0">
                                    <label className="form-label">Password</label>
                                    <Field
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        component={FormikInput}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleShowPassword}
                                                    onMouseDown={mouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <img src={images.passwordShow} alt="eye" />
                                                    ) : (
                                                        <img src={images.passwordHide} alt="eye" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                <div className="checkbox-block">
                                    <Field
                                        name="rememberMe"
                                        id="rememberMe"
                                        label="Remember me"
                                        component={FormikCheckBox}
                                    />
                                </div>
                                <Button color="primary" type="submit" variant="contained" fullWidth title="Login">
                                    Login
                                </Button>
                            </form>
                        );
                    }}
                </Formik>
                <div className="link-block mt-10 text-center">
                    <Link to="#" title="Forgot Password?" onClick={handleForgotPasswordClick}>
                        Forgot Password?
                    </Link>
                </div>
            </DialogContent>
        </>
    );
};

export default LoginForm;
