import React from 'react';
import _ from 'lodash';
import { FormHelperText, TextField } from '@mui/material';

const FormikLabelInput = ({ field: { ...fields }, form: { touched, errors }, ...props }): React.ReactNode => {
    const { id, maxLength, isDefaultValue, multiline, hasObject = false, noMaxLength, ...rest } = props;
    const error = Boolean(_.get(touched, fields?.name) && _.get(errors, fields?.name));
    const getError = () => {
        let errorString = errors;
        if (hasObject) fields?.name?.split('.').map((name) => (errorString = errorString[name]));
        return errorString;
    };
    return isDefaultValue ? (
        <>
            <TextField
                {...fields}
                {...rest}
                id={id}
                fullWidth={true}
                variant="outlined"
                multiline={multiline}
                error={error}
                autoComplete="off"
                inputProps={{
                    maxLength: noMaxLength ? -1 : maxLength ? maxLength : 100,
                }}
            />
            {error && <FormHelperText error>{error && (hasObject ? getError() : errors[fields?.name])}</FormHelperText>}
        </>
    ) : (
        <>
            <TextField
                {...fields}
                {...rest}
                id={id}
                variant="outlined"
                value={props.type === 'number' ? fields?.value || '' : fields?.value?.toString()?.trimStart()}
                multiline={multiline}
                error={error}
                autoComplete="off"
                inputProps={{
                    maxLength: noMaxLength ? -1 : maxLength ? maxLength : 100,
                }}
            />
            {error && <FormHelperText error>{error && (hasObject ? getError() : errors[fields?.name])}</FormHelperText>}
        </>
    );
};

export default FormikLabelInput;
