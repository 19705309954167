export enum FileType {
    PDF = 'application/pdf',
    JPEG = 'image/jpeg',
    JPG = 'image/jpg',
    PNG = 'image/png',
}

export enum UserFriendlyFileType {
    PDF = 'PDF',
    JPEG = 'JPEG',
    JPG = ' JPG',
    PNG = 'PNG',
}
