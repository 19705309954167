import React from 'react';
import { Container, Tab, Tabs } from '@mui/material';
import { Link, Outlet, useMatch } from 'react-router-dom';
import { Routing } from 'shared/constants/routing';
import { useSelector } from 'react-redux';
import { IApplicationState } from 'store/state/app-state';
import { env } from 'env';
import ability from 'shared/ability';
import { CAN_VIEW } from 'shared/constants/actions';
import { ApplicationModule } from 'shared/enums/application-module';

const PATH_PREFIX = env.REACT_APP_PATH_PREFIX;

const Layout: React.FC = () => {
    const userData = useSelector((state: IApplicationState) => state?.AuthData);
    const match = useMatch(`/${PATH_PREFIX}/:childPath/*`);

    const [tabValue, setTabValue] = React.useState(Routing.Resource);

    React.useEffect(() => {
        setTabValue(`${PATH_PREFIX}/${match?.params.childPath}`);
    }, [match]);

    const tabChangeClick = (event: React.SyntheticEvent, tabNewValue: string) => setTabValue(tabNewValue);

    return (
        <div className="dashboard-page">
            <Container maxWidth="xl">
                {userData?.userId && (
                    <div className="tab-block">
                        <Tabs
                            value={tabValue}
                            onChange={tabChangeClick}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                        >
                            <Tab
                                component={Link}
                                to={`../${Routing.Resource}`}
                                label="Useful Links and Resources"
                                value={Routing.Resource}
                            />
                            <Tab component={Link} to={`../${Routing.Member}`} label="Members" value={Routing.Member} />
                            <Tab
                                component={Link}
                                to={`../${Routing.ContractProvider}`}
                                label="Providers"
                                value={Routing.ContractProvider}
                            />
                            {ability.can(CAN_VIEW, ApplicationModule.Attestation_Form_Reports) && (
                                <Tab
                                    component={Link}
                                    to={`../${Routing.AttestationForms}`}
                                    label="Attestation Form Reports"
                                    value={Routing.AttestationForms}
                                />
                            )}
                            {ability.can(CAN_VIEW, ApplicationModule.Super_Admin) && (
                                <Tab
                                    component={Link}
                                    to={`../${Routing.SuperAdmin}`}
                                    label="Super Admin"
                                    value={Routing.SuperAdmin}
                                />
                            )}
                            {ability.can(CAN_VIEW, ApplicationModule.Manage_Content) && (
                                <Tab
                                    component={Link}
                                    to={`../${Routing.ContentList}`}
                                    label="Manage Content"
                                    value={Routing.ContentList}
                                />
                            )}
                        </Tabs>
                    </div>
                )}
                <div className="tab-box-outer">
                    <Outlet />
                </div>
            </Container>
        </div>
    );
};

export default Layout;
