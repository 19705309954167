import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/main.scss';
import { Loader, Spinner } from 'layout';
import { ThemeProvider } from '@mui/material';
import appTheme from 'assets/themes/App.theme';
// import UIAppRouting from 'shared/UIAppRouting';
import AppRouting from 'shared/AppRouting';
import ErrorBoundary from 'shared/components/ErrorBoundary';

const App: React.FC = () => {
    return (
        <>
            <ToastContainer limit={3} autoClose={5000} className="toaster" hideProgressBar={true} />
            <Suspense fallback={<Spinner />}>
                <Loader />
                <ThemeProvider theme={appTheme}>
                    <ErrorBoundary>
                        {/* <UIAppRouting /> */}
                        <AppRouting />
                    </ErrorBoundary>
                </ThemeProvider>
            </Suspense>
        </>
    );
};

export default App;
