import { Typography, DialogContent, InputAdornment, IconButton, Button } from '@mui/material';
import images from 'assets/images';
import { Formik, Field } from 'formik';
import { IChangePasswordModel } from 'models/account';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormikInput } from 'shared/material-ui-formik';
import { IApplicationState } from 'store/state/app-state';
import authService from 'services/auth/auth-service';
import { toast } from 'react-toastify';
import messages from 'shared/constants/messages';
import { HttpStatusCode } from 'shared/enums/http-status-code';
import { ChangePasswordValidationSchema } from 'validation/account/change-password-validation';

interface IChangePasswordFormProps {
    handleDialogClose: () => void;
}

const ChangePasswordForm: React.FC<IChangePasswordFormProps> = ({ handleDialogClose }) => {
    const userData = useSelector((state: IApplicationState) => state?.AuthData);
    const [showPassword, setShowPassword] = React.useState({
        current: false,
        new: false,
        confirm: false,
    });

    const initialState: IChangePasswordModel = {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        userId: userData?.userId,
    };

    const handleShowPassword = (name: string) => setShowPassword({ ...showPassword, [name]: !showPassword[name] });

    const mouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = async (data: IChangePasswordModel) => {
        await authService
            .changePassword(data)
            .then((response) => {
                const { message, statusCode } = response.data;
                if (statusCode === HttpStatusCode.Success) {
                    toast.success(messages.PasswordChangeSuccess);
                    handleDialogClose();
                } else if (statusCode === HttpStatusCode.PasswordNotMatch) {
                    toast.error(message);
                }
            })
            .catch(() => toast.error(messages.SomethingWentWrong));
    };

    return (
        <>
            <div className="MuiDialogTitle-root">
                <Typography variant="h2">Change Password</Typography>
            </div>
            <DialogContent>
                <Formik
                    initialValues={initialState}
                    onSubmit={handleSubmit}
                    validationSchema={ChangePasswordValidationSchema}
                    validateOnBlur={false}
                    validateOnChange={true}
                >
                    {(props) => {
                        const { handleSubmit } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="form-label">Current Password</label>
                                    <Field
                                        name="currentPassword"
                                        type={showPassword?.current ? 'text' : 'password'}
                                        component={FormikInput}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleShowPassword('current')}
                                                    onMouseDown={mouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword?.current ? (
                                                        <img src={images.passwordShow} alt="eye" />
                                                    ) : (
                                                        <img src={images.passwordHide} alt="eye" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">New Password</label>
                                    <Field
                                        name="newPassword"
                                        type={showPassword?.new ? 'text' : 'password'}
                                        component={FormikInput}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleShowPassword('new')}
                                                    onMouseDown={mouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword?.new ? (
                                                        <img src={images.passwordShow} alt="eye" />
                                                    ) : (
                                                        <img src={images.passwordHide} alt="eye" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                <div className="form-group mb-30">
                                    <label className="form-label">Confirm New Password</label>
                                    <Field
                                        name="confirmNewPassword"
                                        type={showPassword?.confirm ? 'text' : 'password'}
                                        component={FormikInput}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleShowPassword('confirm')}
                                                    onMouseDown={mouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword?.confirm ? (
                                                        <img src={images.passwordShow} alt="eye" />
                                                    ) : (
                                                        <img src={images.passwordHide} alt="eye" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    title="Change Password"
                                >
                                    Change Password
                                </Button>
                            </form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </>
    );
};

export default ChangePasswordForm;
