import { AuthActionType } from 'store/action-types/auth-action-type';
import { ILoginResponseModel } from 'models/account';
import { ExtractSubjectType, MongoQuery, Subject, SubjectRawRule } from '@casl/ability';
import { IPermissionModel } from 'store/state/auth-state';
import ability from 'shared/ability';
import { ConvertAbility } from 'shared/utils/abilityHelper';
import { UserType } from 'shared/enums/user-type';
import { ApplicationModule } from 'shared/enums/application-module';

export const login = (loginData: ILoginResponseModel) => {
    let rolePermissions: Array<IPermissionModel> = loginData?.permissions ? JSON.parse(loginData?.permissions) : [];
    let abilities: Array<SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery>> = [];

    if (loginData?.userTypeId === UserType.SuperAdmin) {
        rolePermissions = Object.values(ApplicationModule)?.map((menu) => {
            return {
                ModuleName: menu,
                CanRead: true,
                CanWrite: true,
                CanEdit: true,
                CanDelete: true,
            };
        });
    }

    abilities = ConvertAbility(rolePermissions || []);

    // Update ability rules
    ability.update(abilities);

    const authConfigs = {
        refreshToken: loginData?.refreshToken,
        accessToken: loginData?.jwtToken,
        expiration: loginData?.expiration,
    };
    return { type: AuthActionType.LOGIN_REQUEST, payload: { ...loginData, authConfigs, abilities } };
};

export const logout = () => {
    ability.update([]);
    return { type: AuthActionType.LOGOUT_REQUEST, payload: {} };
};

export const updateUsername = (userName: string) => {
    return { type: AuthActionType.USERNAME_UPDATE, payload: userName };
};

export const rememberUser = (userEmail: string, password: string) => {
    return { type: AuthActionType.REMEMBER_USER, payload: { userEmail, password } };
};
