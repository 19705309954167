import React from 'react';
import PublicLayout from './PublicLayout';
import SecureLayout from './SecureLayout';
import { useSelector } from 'react-redux';
import { IApplicationState } from 'store/state/app-state';
import Scrollbars from 'react-custom-scrollbars-2';

const MainLayout: React.FC = () => {
    const authState = useSelector((state: IApplicationState) => state?.AuthData);

    return (
        <Scrollbars
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin="100vh"
            thumbMinSize={20}
            universal={true}
            renderTrackVertical={(props) => <div {...props} className="custom-track-vertical" />}
            renderThumbVertical={(props) => <div {...props} className="custom-thumb-vertical" />}
            renderView={(props) => <div {...props} className="custom-view" />}
        >
            <React.Fragment>{authState?.authConfigs?.accessToken ? <SecureLayout /> : <PublicLayout />}</React.Fragment>
        </Scrollbars>
    );
};

export default MainLayout;
