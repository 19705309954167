import { env } from 'env';

const PATH_PREFIX = env.REACT_APP_PATH_PREFIX;

export const Routing = {
    Forbidden: `${PATH_PREFIX}/forbidden`,
    Home: `${PATH_PREFIX}/`,
    AboutUs: `${PATH_PREFIX}/about-us`,
    ContactUs: `${PATH_PREFIX}/contact-us`,
    Career: `${PATH_PREFIX}/career`,
    Resource: `${PATH_PREFIX}/resource`,
    Member: `${PATH_PREFIX}/member`,
    ContractProvider: `${PATH_PREFIX}/provider`,
    AttestationForms: `${PATH_PREFIX}/attestation-forms`,
    AttestationFormView: `${PATH_PREFIX}/attestation-forms/:id`,
    SuperAdmin: `${PATH_PREFIX}/super-admin`,
    ContentList: `${PATH_PREFIX}/manage-content`,
    ContentForm: `${PATH_PREFIX}/manage-content/:id`,

    //Resources
    UsefulLinks: `useful-links`,
    UsefulLinksForm: `useful-links/add`,
    HealthEducation: `health-education`,
    HealthEducationManage: `health-education/:id`,
    LosAngelesCounty: `los-angeles-county`,
    LosAngelesCountyManage: `los-angeles-county/:id`,
    CDCResources: `cdc-resources`,
    CDCResourceManage: `cdc-resources/:id`,
    CultureLinguistic: `culture-linguistic-resources`,
    CultureLinguisticManage: `culture-linguistic-resources/:id`,
    OtherResources: `other-resources`,
    OtherResourceManage: `other-resources/:id`,

    //Members
    MemberSatisfactionSurvey: `satisfaction-survey`,
    MemberSatisfactionSurveyManage: `satisfaction-survey/:id`,
    UrgentCareInfo: `urgent-care-info`,
    UrgentCareInfoManage: `urgent-care-info/add`,
    PatientRights: `patient-rights`,
    PatientRightsManage: `patient-rights/:id`,
    AppealGrievance: `appeal-grievance`,
    AppealGrievanceManage: `appeal-grievance/:id`,
    HealthCareOption: `healthcare-option`,
    HealthCareOptionManage: `healthcare-option/:id`,
    OtherMembers: `others`,
    OtherMembersManage: `others/:id`,

    //Contract Providers
    PhysicianSatisfactionSurvey: 'physician-satisfaction-survey',
    PhysicianSatisfactionSurveyManage: 'physician-satisfaction-survey/:id',
    ACSTMaterial: 'access-care-standard-material',
    ACSTMaterialManage: 'access-care-standard-material/:id',
    FWATMaterial: 'fraud-waste-abuse-material',
    FWATMaterialManage: 'fraud-waste-abuse-material/:id',
    HIPAAMaterial: 'hipaa-confdentiality-material',
    HIPAAMaterialManage: 'hipaa-confdentiality-material/:id',
    ProviderMaterial: 'provider-material',
    ProviderMaterialManage: 'provider-material/:id',
    SnpMaterial: 'snp-moc-material',
    SnpMaterialManage: 'snp-moc-material/:id',
    MemoForm: 'memo-form',
    MemoFormManage: 'memo-form/:id',
    TrainingMaterial: 'training-material',
    TrainingMaterialManage: 'training-material/:id',
    TrainingMaterialPreview: 'training-material/preview',
    CPAttestationForm: 'attestation-form',
    OtherContractProviders: 'others',
    OtherContractProvidersManage: 'others/:id',

    //SuperAdmin
    Role: 'role',
    RoleManage: 'role/:id',
    RolePermission: 'role-permission',
    User: 'user',
    UserView: 'user/view/:id',
    UserManage: 'user/manage/:id',
    UserPermission: 'user-permission',
};
