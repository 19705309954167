import { ExtractSubjectType, MongoQuery, Subject, SubjectRawRule } from '@casl/ability';
import { CAN_ADD, CAN_DELETE, CAN_UPDATE, CAN_VIEW } from 'shared/constants/actions';
import { IPermissionModel } from 'store/state/auth-state';

export function ConvertAbility(
    permissions: IPermissionModel[],
): SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery>[] {
    const abilities = new Array<SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery>>();

    permissions.forEach((permission: IPermissionModel) => {
        if (permission.CanRead || permission.CanWrite || permission.CanEdit || permission.CanDelete) {
            const actions: string[] = [];
            if (permission.CanRead || permission.CanWrite || permission.CanEdit || permission.CanDelete)
                actions.push(CAN_VIEW);

            if (permission.CanWrite) actions.push(CAN_ADD);
            if (permission.CanEdit) actions.push(CAN_UPDATE);
            if (permission.CanDelete) actions.push(CAN_DELETE);
            abilities.push({
                subject: permission.ModuleName,
                action: actions,
            });
        }
    });

    return abilities;
}
