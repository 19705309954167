import React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import images from 'assets/images';
import { Button, Container, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'store/actions/auth-action';
import { Routing } from 'shared/constants/routing';
import ConfirmDialog from 'shared/components/ConfirmDialog';
import { IApplicationState } from 'store/state/app-state';
import TopHeader from './TopHeader';
import { stringAvatar } from 'shared/utils/commonFunctions';
import messages from 'shared/constants/messages';
import ChangePasswordForm from 'pages/auth/ChangePasswordForm';
import DialogForm from 'shared/components/DialogForm';

const SecureHeader: React.FC = () => {
    const userData = useSelector((state: IApplicationState) => state?.AuthData);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [userMenu, setUserMenu] = React.useState<null | HTMLElement>(null);
    const [userMenuOpen, setUserMenuOpen] = React.useState(false);
    const [openChangePasswordDialog, setChangePasswordDialogOpen] = React.useState(false);

    const handleConfirmDialogOpen = () => {
        menuCloseClick();
        setOpenConfirmDialog(true);
    };
    const handleConfirmDialogClose = () => setOpenConfirmDialog(false);

    const handleChangePasswordDialogOpen = () => {
        menuCloseClick();
        setChangePasswordDialogOpen(true);
    };
    const handleChangePasswordDialogClose = () => setChangePasswordDialogOpen(false);

    // Humbugger Menu JS
    const handleMobileClick = () => document.body.classList.toggle('menu-open');
    const handleSideMenuCloseClick = () => document.body.classList.remove('menu-open');

    const handleLogoutClick = async () => {
        navigate(Routing.Home);
        handleSideMenuCloseClick();
        await dispatch(logout());
    };

    const menuOpenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setUserMenu(event.currentTarget);
        setUserMenuOpen(true);
    };
    const menuCloseClick = () => {
        setUserMenuOpen(false);
    };

    const handleDashboardClick = () => {
        menuCloseClick();
        handleSideMenuCloseClick();
        navigate(Routing.Resource);
    };

    return (
        <>
            <header className="header admin-header">
                <TopHeader />
                <div className="header_bottom">
                    <Container maxWidth="xl">
                        <div className="user-info">
                            <div className="hamburger-menu" onClick={handleMobileClick}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <Link to={Routing.Home} title="Brand" className="logo">
                                <img src={images.Logo} alt="logo" />
                            </Link>
                        </div>
                        <nav>
                            <ul>
                                <li>
                                    <NavLink to={Routing.Home} end title="Home" onClick={handleSideMenuCloseClick}>
                                        Home Page
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={Routing.AboutUs} title="About Us" onClick={handleSideMenuCloseClick}>
                                        About Us
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={Routing.ContactUs}
                                        title="Contact Us"
                                        onClick={handleSideMenuCloseClick}
                                    >
                                        Contact Us
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={Routing.Career}
                                        title="Career Opportunities"
                                        onClick={handleSideMenuCloseClick}
                                    >
                                        Career Opportunities
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                        <div className="user-menu">
                            <Button
                                id="user-button"
                                aria-controls={open ? 'user-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={menuOpenClick}
                                className="user-menu-button"
                                color="primary"
                                variant="contained"
                            >
                                {stringAvatar(userData?.userName)}
                            </Button>
                            <Menu
                                id="user-menu"
                                anchorEl={userMenu}
                                open={userMenuOpen}
                                onClose={menuCloseClick}
                                MenuListProps={{
                                    'aria-labelledby': 'user-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                className="user-menu-outer"
                            >
                                <div className="user-menu-info">
                                    <span>Welcome</span>
                                    <p>{userData?.userName}</p>
                                </div>
                                <MenuItem onClick={handleDashboardClick}>
                                    <ListItemIcon>
                                        <img src={images.Dashboard} alt="dashboard" />
                                    </ListItemIcon>
                                    My Dashboard
                                </MenuItem>
                                <MenuItem onClick={handleChangePasswordDialogOpen}>
                                    <ListItemIcon>
                                        <img src={images.ChangePassword} alt="Change Password" />
                                    </ListItemIcon>
                                    Change Password
                                </MenuItem>
                                <MenuItem onClick={handleConfirmDialogOpen}>
                                    <ListItemIcon>
                                        <img src={images.logout} alt="logout" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </div>
                    </Container>
                </div>
            </header>
            <DialogForm
                scroll="paper"
                openDialog={openChangePasswordDialog}
                handleDialogClose={handleChangePasswordDialogClose}
                className="modal-small"
                bodyContent={<ChangePasswordForm handleDialogClose={handleChangePasswordDialogClose} />}
            />
            <ConfirmDialog
                openDialog={openConfirmDialog}
                handleDialogClose={handleConfirmDialogClose}
                handleSuccess={handleLogoutClick}
                succssBtnLabel="Logout"
                title="Logout"
                message={messages.LogoutConfirm}
            />
        </>
    );
};

export default SecureHeader;
