import { AxiosResponse } from 'axios';
import { ApiResponseModel } from 'models/api';
import { IBaseSearchRequest, SearchResult } from 'models/base-type';
import { ISettingModel } from 'models/settings';
import baseService from 'services/base-service';

const endPointBaseURL = `setting`;

const getSettings = async (
    requestBody: IBaseSearchRequest,
): Promise<AxiosResponse<ApiResponseModel<SearchResult<ISettingModel>>>> =>
    baseService.post<ApiResponseModel<SearchResult<ISettingModel>>>(`${endPointBaseURL}/getall`, requestBody);

const getSettingsByName = async (
    SettingNames?: string,
): Promise<AxiosResponse<ApiResponseModel<Array<ISettingModel>>>> =>
    baseService.get<ApiResponseModel<Array<ISettingModel>>>(
        `${endPointBaseURL}/getbynames?SettingNames=${SettingNames}`,
    );

const getSettingByNameEnc = async (SettingNameEnc?: string): Promise<AxiosResponse<ApiResponseModel<ISettingModel>>> =>
    baseService.get<ApiResponseModel<ISettingModel>>(`${endPointBaseURL}/getbyname?SettingNameEnc=${SettingNameEnc}`);

const getSettingsByGroupId = async (
    settingGroupIds?: string,
): Promise<AxiosResponse<ApiResponseModel<Array<ISettingModel>>>> =>
    baseService.get<ApiResponseModel<Array<ISettingModel>>>(
        `${endPointBaseURL}/getbygroupid?settingGroupIds=${settingGroupIds}`,
    );

const updateSetting = async (
    model: ISettingModel,
    loginId: string,
): Promise<AxiosResponse<ApiResponseModel<ISettingModel>>> =>
    baseService.put<ApiResponseModel<ISettingModel>>(`${endPointBaseURL}/update?loginId=${loginId}`, model);

export default { getSettings, getSettingsByName, getSettingByNameEnc, getSettingsByGroupId, updateSetting };
