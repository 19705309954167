export enum HttpStatusCode {
    InternalServerError = 500,
    Success = 200,
    Error = 201,
    UserNotFound = 204,
    InvalidToken = 205,
    PasswordLinkExpired = 206,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    ConflictError = 409,
    RoleInUse = 601,
    DuplicateAttestationForm = 602,
    DuplicateRole = 603,
    DuplicateUser = 604,
    PasswordNotMatch = 605,
    NeedPasswordReset = 606,
}
