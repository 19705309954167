import { format, addMinutes } from 'date-fns';
// import { convertToTimeZone } from 'date-fns-timezone';

export const DurationByMinutes = (totalMinutes) => {
    const minutes = totalMinutes % 60;
    const hours = (totalMinutes - minutes) / 60;
    const days = hours / 60;
    return days >= 1
        ? `${days} day`
        : hours === 0
          ? `${minutes} min`
          : minutes === 0
            ? `${hours} ${hours === 1 ? 'hr' : 'hrs'}`
            : `${hours} ${hours === 1 ? 'hr' : 'hrs'} ${minutes} min`;
};

export const DateToLocalTimeString = (date: Date) => format(new Date(date), 'hh:mm a');

export const DateToMessageDateString = (date: Date) => format(new Date(`${date?.toString()}Z`), 'MMM dd hh:mm a');

export const DateToLocalDateString = (date: Date) => format(new Date(date), 'MM/dd/yyyy');

export const DateToLocalMonthYearString = (date: Date) => format(new Date(date), 'MMM-yyyy');

export const DateToLocalYearString = (date: Date) => format(new Date(date), 'yyyy');

export const DateToCalendarTimeString = (date: Date) => format(new Date(date), 'h:mma')?.toLowerCase();

export const DateToCalendarTimeTextString = (date: Date) => format(new Date(date), 'h:mm')?.toLowerCase();

export const AddMinutesToDate = (date: Date, duration: number) => addMinutes(date, duration);

export const DateUTCToLocalDateString = (date: Date) => format(new Date(`${date?.toString()}Z`), 'dd-MMM-yyyy');

export const DateUTCToLocalTimeString = (date: Date) => format(new Date(`${date?.toString()}Z`), 'hh:mm a');

export const DateUTCToLocalMonthYearString = (date: Date) => format(new Date(`${date?.toString()}Z`), 'MMM-yyyy');

export const DateUTCToLocalDate = (date: Date) => new Date(`${date?.toString()}Z`);

export const DateUTCToLocalDateTimeString = (date: Date) =>
    format(new Date(`${date?.toString()}Z`), 'dd-MMM-yyyy hh:mm a');

export const DateToJsonDateString = (date: Date) => format(new Date(date), 'yyyy-MM-dd');

export const CalculateAge = (birthDate: Date) => {
    const ageDifMs: number = Date.now() - birthDate?.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate?.getUTCFullYear() - 1970);
};

export const GetLocalTimeZoneStandardName = () =>
    new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4);

export const GetLocalTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const DateWithoutTime = function (date: Date) {
    const d = new Date(date);
    d.setHours(0, -d.getTimezoneOffset(), 0, 0); // adding/removing the timezone offset for adjustment at backend side
    return d;
};
