import { IPageModelBase } from 'models/base-type';

export const ORDER_BY_ASC = 'asc';
export const ORDER_BY_DESC = 'desc';
export type ORDER_BY_TYPE = 'asc' | 'desc';
export enum ORDER_BY_ENUM {
    asc,
    desc,
}
export const DEFAULT_ORDERBY = 'createdDate';
export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_OPTIONS = [10, 20, 50, 100];

export const PAGE_DETAIL: IPageModelBase = {
    page: 1,
    rowsPerPage: DEFAULT_PAGE_SIZE,
    order: ORDER_BY_ASC,
    orderBy: DEFAULT_ORDERBY,
};

export const FIRST_COLUMN_ASC = [
    {
        column: 0,
        dir: 0,
    },
];
