import { IApplicationState } from 'store/state/app-state';
import CryptoJS from 'crypto-js';
import { env } from 'env';

export const loadStorage = (): IApplicationState => {
    const decryptedStore = localStorage.getItem('phmso-state');
    try {
        if (decryptedStore === null) {
            return undefined;
        } else {
            const bytesStore = CryptoJS.AES.decrypt(decryptedStore, env.REACT_APP_CIPHER);
            const serializedStore = bytesStore.toString(CryptoJS.enc.Utf8);
            return JSON.parse(serializedStore);
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error While Getting LocalStorage State!!!');
    }
};

export const saveStorage = (state: IApplicationState) => {
    try {
        const serializedState = JSON.stringify(state);
        return localStorage.setItem(
            'phmso-state',
            CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(serializedState), env.REACT_APP_CIPHER).toString(),
        );
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error While Saving LocalStorage State!!!');
    }
};
