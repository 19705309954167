import React from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import images from 'assets/images';
import { Button, Container, Dialog } from '@mui/material';
import DialogForm from 'shared/components/DialogForm';
import LoginForm from 'pages/auth/LoginForm';
import { HttpStatusCode } from 'shared/enums/http-status-code';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import authService from 'services/auth/auth-service';
import messages from 'shared/constants/messages';
import { login, rememberUser } from 'store/actions/auth-action';
import { ILoginModel, ILoginResponseModel } from 'models/account';
import { Routing } from 'shared/constants/routing';
import TopHeader from './TopHeader';
import { ISettingModel } from 'models/settings';
import settingService from 'services/settings/setting-service';
import { SettingNames } from 'shared/enums/settings';
import { getSettingValue } from 'shared/utils/commonFunctions';
import ResetPasswordForm from 'pages/auth/ResetPasswordForm';
import ForgotPasswordForm from 'pages/auth/ForgotPasswordForm';

const PublicHeader: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const urlSearch = new URLSearchParams(location.search);

    const userIdRef = React.useRef('');
    const tokenRef = React.useRef('');

    const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
    const [settings, setSettings] = React.useState<Array<ISettingModel>>();
    const [openResetPasswordDialog, setResetPasswordDialogOpen] = React.useState(false);
    const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = React.useState(false);

    React.useEffect(() => {
        (async function () {
            await getSettingsByName();
        })();
        if (urlSearch.get('token')) {
            tokenRef.current = urlSearch.get('token');
            handleResetPasswordDialogOpen();
        }
    }, []);

    const getSettingsByName = async () => {
        await settingService
            .getSettingsByName(`${SettingNames.PROVIDER_LOGIN_URL}`)
            .then((response) => {
                const { data } = response.data;
                setSettings(data);
            })
            .catch(() => toast.error(messages.SomethingWentWrong));
    };

    // Humbugger Menu JS
    const handleMobileClick = () => document.body.classList.toggle('menu-open');
    const handleSideMenuCloseClick = () => document.body.classList.remove('menu-open');

    const handleLoginDialogOpen = () => setOpenLoginDialog(true);
    const handleLoginDialogClose = () => setOpenLoginDialog(false);

    const handleResetPasswordDialogOpen = () => setResetPasswordDialogOpen(true);
    const handleResetPasswordDialogClose = () => setResetPasswordDialogOpen(false);

    const handleForgotPasswordDialogOpen = () => setOpenForgotPasswordDialog(true);
    const handleForgotPasswordDialogClose = () => setOpenForgotPasswordDialog(false);

    const handleForgotPasswordClick = () => {
        handleLoginDialogClose();
        handleForgotPasswordDialogOpen();
    };

    const handleLogin = async (data: ILoginModel) => {
        await authService
            .login(data)
            .then((response) => {
                const { data: responseData, statusCode } = response.data;
                if (statusCode === HttpStatusCode.Success) {
                    dispatch(login(responseData as ILoginResponseModel));

                    if (data?.rememberMe) dispatch(rememberUser(data?.userName, data?.password));
                    else dispatch(rememberUser('', ''));

                    handleSideMenuCloseClick();
                    navigate(`${Routing.Resource}/${Routing.UsefulLinks}`);
                } else if (statusCode === HttpStatusCode.NeedPasswordReset) {
                    userIdRef.current = responseData as string;
                    handleLoginDialogClose();
                    handleResetPasswordDialogOpen();
                }
            })
            .catch(() => toast.error(messages.SomethingWentWrong));
    };

    return (
        <header className="header">
            <TopHeader />
            <div className="header_bottom">
                <Container maxWidth="xl">
                    <div className="user-info">
                        <div className="hamburger-menu" onClick={handleMobileClick}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <Link to={Routing.Home} title="Brand" className="logo">
                            <img src={images.Logo} alt="logo" />
                        </Link>
                    </div>
                    <nav>
                        <ul>
                            <li>
                                <NavLink to={Routing.Home} end title="Home" onClick={handleSideMenuCloseClick}>
                                    Home Page
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={Routing.AboutUs} title="About Us" onClick={handleSideMenuCloseClick}>
                                    About Us
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={Routing.ContactUs} title="Contact Us" onClick={handleSideMenuCloseClick}>
                                    Contact Us
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={Routing.Career}
                                    title="Career Opportunities"
                                    onClick={handleSideMenuCloseClick}
                                >
                                    Career Opportunities
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={Routing.Resource}
                                    title="Useful Links and Resources"
                                    onClick={handleSideMenuCloseClick}
                                >
                                    Useful Links and Resources
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={Routing.Member} title="Members" onClick={handleSideMenuCloseClick}>
                                    Members
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={Routing.ContractProvider}
                                    title="Providers"
                                    onClick={handleSideMenuCloseClick}
                                >
                                    Providers
                                </NavLink>
                            </li>
                        </ul>
                        <div className="button-block">
                            <Button
                                variant="outlined"
                                onClick={handleLoginDialogOpen}
                                color="primary"
                                title="Internal Login"
                            >
                                Internal Login
                            </Button>
                            <Button
                                component={Link}
                                to={getSettingValue(settings, SettingNames.PROVIDER_LOGIN_URL)}
                                target="_blank"
                                variant="outlined"
                                color="primary"
                                title="Providers Login"
                            >
                                Providers Login
                            </Button>
                        </div>
                    </nav>
                    <DialogForm
                        scroll="paper"
                        openDialog={openLoginDialog}
                        handleDialogClose={handleLoginDialogClose}
                        className="modal-small"
                        bodyContent={
                            <LoginForm
                                handleLogin={handleLogin}
                                handleForgotPasswordClick={handleForgotPasswordClick}
                            />
                        }
                    />
                    <DialogForm
                        scroll="paper"
                        openDialog={openForgotPasswordDialog}
                        handleDialogClose={handleForgotPasswordDialogClose}
                        className="modal-small"
                        bodyContent={<ForgotPasswordForm handleDialogClose={handleForgotPasswordDialogClose} />}
                    />
                    <Dialog
                        open={openResetPasswordDialog}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                handleResetPasswordDialogClose();
                            }
                        }}
                        scroll="paper"
                        className="modal-small"
                    >
                        {
                            <ResetPasswordForm
                                handleDialogClose={handleResetPasswordDialogClose}
                                userId={userIdRef?.current}
                                token={tokenRef?.current}
                            />
                        }
                    </Dialog>
                </Container>
            </div>
        </header>
    );
};

export default PublicHeader;
