import React from 'react';

function DesignerFooter() {
    return (
        <>
            <footer className="footer">
                <p className="copyright">@ Pacifc Health MSO, Inc. 2024, All Rights Reserved</p>
            </footer>
        </>
    );
}

export default DesignerFooter;
