/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthActionType } from 'store/action-types/auth-action-type';
import { IAction } from 'store/state/app-state';
import { IAuthState } from 'store/state/auth-state';

const AuthReducer = (state: IAuthState = null, action: IAction<any>): IAuthState => {
    switch (action?.type) {
        case AuthActionType.LOGIN_REQUEST:
            return {
                ...state,
                authConfigs: action?.payload?.authConfigs,
                userName: action.payload.userName,
                userId: action.payload.userId,
                userTypeId: action.payload.userTypeId,
                abilities: action.payload.abilities,
            };
        case AuthActionType.USERNAME_UPDATE:
            return {
                ...state,
                userName: action?.payload,
            };
        case AuthActionType.REMEMBER_USER:
            return {
                ...state,
                ...action?.payload,
            };
        case AuthActionType.LOGOUT_REQUEST:
            return { ...action?.payload, userEmail: state?.userEmail, password: state?.password };
    }
    return state;
};

export default AuthReducer;
