/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import _ from 'lodash';
import { Button, FormHelperText, TextField } from '@mui/material';
import { FileType } from 'shared/enums/file-types';

export interface IFormikFileInputProps {
    id?: string;
    name?: string;
    value?: string;
    title?: string;
    buttonLabel?: string;
    className?: string;
    fileName?: string;
    hideDownloadIcon?: boolean;
    handleFileClick?: () => void;
    handleFileChange?: (files: Array<any>) => void;
    handleFileDelete?: () => void;
    disabledTitle?: boolean;
    disabled?: boolean;
    allowedTypes?: Array<string>;
}

const FormikFileInput = ({ field: { ...fields }, form: { touched, errors }, ...props }): React.ReactNode => {
    const { handleFileChange, disabled, allowedTypes }: IFormikFileInputProps = props;
    const error = Boolean(_.get(touched, fields?.name) && _.get(errors, fields?.name));
    const uploadInputRef = React.useRef(null);

    const handleFileUploadChange = async () => {
        if (handleFileChange) {
            await handleFileChange(uploadInputRef.current?.files);
        }
        uploadInputRef.current.value = null;
    };

    return (
        <>
            <div className="field-with-button">
                <div className="field-with-label align-items-start">
                    {props?.label && <label className="form-label input-error-label">{props?.label}</label>}
                    <div className="field-box">
                        <TextField
                            {...fields}
                            value={fields?.value?.toString()?.trimStart()}
                            variant="outlined"
                            fullWidth
                            disabled={disabled}
                            error={error}
                        />
                        {error && <FormHelperText error>{error && errors[fields?.name]}</FormHelperText>}
                    </div>
                </div>
                <div className="upload-button">
                    <Button variant="contained" color="primary" className="upload-btn" disabled={disabled}>
                        <input
                            type="file"
                            accept={allowedTypes ? allowedTypes.join(',') : FileType.PDF}
                            className="file-upload"
                            onChange={handleFileUploadChange}
                            ref={uploadInputRef}
                            disabled={disabled}
                        />
                        Browse
                    </Button>
                </div>
            </div>
        </>
    );
};

export default FormikFileInput;
