import { Typography, DialogContent, InputAdornment, IconButton, Button } from '@mui/material';
import images from 'assets/images';
import { Formik, Field } from 'formik';
import { IChangePasswordModel, IResetPasswordModel } from 'models/account';
import React from 'react';
import { FormikInput } from 'shared/material-ui-formik';
import authService from 'services/auth/auth-service';
import { toast } from 'react-toastify';
import messages from 'shared/constants/messages';
import { HttpStatusCode } from 'shared/enums/http-status-code';
import { ResetPasswordValidationSchema } from 'validation/account/reset-password-validation';
import { useNavigate } from 'react-router-dom';
import { Routing } from 'shared/constants/routing';

interface IResetPasswordFormProps {
    handleDialogClose: () => void;
    userId?: string;
    token?: string;
}

const ResetPasswordForm: React.FC<IResetPasswordFormProps> = ({ handleDialogClose, userId, token = '' }) => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState({
        new: false,
        confirm: false,
    });

    const getData = () => {
        if (userId) return { newPassword: '', confirmNewPassword: '', userId: userId } as IChangePasswordModel;
        else if (token) return { newPassword: '', token: token, confirmPassword: '' } as IResetPasswordModel;
    };

    const handleShowPassword = (name: string) => setShowPassword({ ...showPassword, [name]: !showPassword[name] });

    const mouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleCancelClick = () => {
        handleDialogClose();
        navigate(Routing.Home);
    };

    const handleSubmit = async (data) => {
        if (userId) {
            await authService
                .changePassword({ ...data, currentPassword: '' } as IChangePasswordModel, true)
                .then((response) => {
                    const { statusCode } = response.data;
                    if (statusCode === HttpStatusCode.Success) {
                        toast.success(messages.PasswordChangeSuccess);
                        handleDialogClose();
                    }
                })
                .catch(() => toast.error(messages.SomethingWentWrong));
        } else if (token) {
            await authService
                .resetPassword({ newPassword: data.newPassword, token: token })
                .then((response) => {
                    const { message, statusCode } = response.data;
                    if (statusCode === HttpStatusCode.Success) {
                        toast.success(messages.PasswordChangeSuccess);
                    } else if (
                        statusCode === HttpStatusCode.InvalidToken ||
                        statusCode === HttpStatusCode.PasswordLinkExpired
                    ) {
                        toast.error(message);
                    }
                    handleDialogClose();
                })
                .catch(() => toast.error(messages.SomethingWentWrong));
        }
    };

    return (
        <>
            <div className="MuiDialogTitle-root">
                <Typography variant="h2">Reset Password</Typography>
            </div>
            <DialogContent>
                {userId && <p className="password-info">Please reset your password to continue with PHMSO.</p>}
                <Formik
                    initialValues={getData()}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                    validationSchema={ResetPasswordValidationSchema}
                    validateOnBlur={false}
                    validateOnChange={true}
                >
                    {(props) => {
                        const { handleSubmit } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="form-label">New Password</label>
                                    <Field
                                        name="newPassword"
                                        type={showPassword?.new ? 'text' : 'password'}
                                        component={FormikInput}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleShowPassword('new')}
                                                    onMouseDown={mouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword?.new ? (
                                                        <img src={images.passwordShow} alt="eye" />
                                                    ) : (
                                                        <img src={images.passwordHide} alt="eye" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                <div className="form-group mb-30">
                                    <label className="form-label">Confirm New Password</label>
                                    <Field
                                        name="confirmNewPassword"
                                        type={showPassword?.confirm ? 'text' : 'password'}
                                        component={FormikInput}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleShowPassword('confirm')}
                                                    onMouseDown={mouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword?.confirm ? (
                                                        <img src={images.passwordShow} alt="eye" />
                                                    ) : (
                                                        <img src={images.passwordHide} alt="eye" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    title="Change Password"
                                >
                                    Change Password
                                </Button>
                                {token && (
                                    <Button
                                        className="mt-10"
                                        color="primary"
                                        variant="outlined"
                                        fullWidth
                                        title="Cancel"
                                        onClick={handleCancelClick}
                                    >
                                        Cancel
                                    </Button>
                                )}
                            </form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </>
    );
};

export default ResetPasswordForm;
