import React from 'react';
import _ from 'lodash';
import { FormControl, FormHelperText, OutlinedInput } from '@mui/material';

const FormikInput = ({ field: { ...fields }, form: { touched, errors }, ...props }): React.ReactNode => {
    const { id, maxLength, isDefaultValue, multiline, hasObject = false, noMaxLength, ...rest } = props;
    const error = Boolean(_.get(touched, fields?.name) && _.get(errors, fields?.name));
    const getError = () => {
        let errorString = errors;
        if (hasObject) fields?.name?.split('.').map((name) => (errorString = errorString[name]));
        return errorString;
    };
    return isDefaultValue ? (
        <FormControl variant="outlined" style={{ display: 'flex' }}>
            <OutlinedInput
                {...fields}
                {...rest}
                id={id}
                fullWidth
                multiline={multiline}
                error={error}
                autoComplete="off"
                inputProps={{
                    className: multiline ? 'p-0' : '',
                    maxLength: noMaxLength ? -1 : maxLength ? maxLength : 100,
                }}
            />
            {error && <FormHelperText error>{error && (hasObject ? getError() : errors[fields?.name])}</FormHelperText>}
        </FormControl>
    ) : (
        <FormControl variant="outlined" style={{ display: 'flex' }}>
            <OutlinedInput
                {...fields}
                {...rest}
                id={id}
                value={props.type === 'number' ? fields?.value || '' : fields?.value?.toString()?.trimStart()}
                multiline={multiline}
                error={error}
                autoComplete="off"
                inputProps={{
                    className: multiline ? 'p-0' : '',
                    maxLength: noMaxLength ? -1 : maxLength ? maxLength : 100,
                }}
            />
            {error && <FormHelperText error>{error && (hasObject ? getError() : errors[fields?.name])}</FormHelperText>}
        </FormControl>
    );
};

export default FormikInput;
