import React, { lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Routing } from './constants/routing';
import MainLayout from 'layout/MainLayout';
import { ApplicationModule } from './enums/application-module';
import { CAN_VIEW } from './constants/actions';
import Layout from 'layout/TabLayout';
import { useSelector } from 'react-redux';
import { IApplicationState } from 'store/state/app-state';
// import ability from 'shared/ability';

interface IRoute {
    path: string;
    page?: string;
    action?: string;
    element?: React.ReactNode;
    index?: boolean;
    children?: Array<IRoute>;
    loginRequired?: boolean;
}

const Forbidden = lazy(() => import('shared/components/Forbidden'));
const Home = lazy(() => import('pages/Home'));
const AboutUs = lazy(() => import('pages/AboutUs'));
const ContactUs = lazy(() => import('pages/ContactUs'));
const Career = lazy(() => import('pages/Career'));

const ResourceLayout = lazy(() => import('pages/UsefulLinkAndResources/ResourceLayout'));
const UsefulLinks = lazy(() => import('pages/UsefulLinkAndResources/UsefulLinks/UsefulLinks'));
const UsefulLinkManage = lazy(() => import('pages/UsefulLinkAndResources/UsefulLinks/UsefulLinkManage'));
const HealthEducation = lazy(() => import('pages/UsefulLinkAndResources/HealthEducation/HealthEducation'));
const HealthEducationManage = lazy(() => import('pages/UsefulLinkAndResources/HealthEducation/HealthEducationManage'));
const LosAngelesCounty = lazy(() => import('pages/UsefulLinkAndResources/LosAngelesCounty/LosAngelesCounty'));
const LosAngelesCountyManage = lazy(
    () => import('pages/UsefulLinkAndResources/LosAngelesCounty/LosAngelesCountyManage'),
);
const CDCResources = lazy(() => import('pages/UsefulLinkAndResources/CDCResources/CDCResources'));
const CDCResourceManage = lazy(() => import('pages/UsefulLinkAndResources/CDCResources/CDCResourceManage'));
const CultureLinguisticResources = lazy(
    () => import('pages/UsefulLinkAndResources/CultureLinguistic/CultureLinguistic'),
);
const CultureLinguisticResourceManage = lazy(
    () => import('pages/UsefulLinkAndResources/CultureLinguistic/CultureLinguisticManage'),
);
const OtherResources = lazy(() => import('pages/UsefulLinkAndResources/OtherResources/OtherResources'));
const OtherResourceManage = lazy(() => import('pages/UsefulLinkAndResources/OtherResources/OtherResourceManage'));

const MembersLayout = lazy(() => import('pages/Members/MembersLayout'));
const MemberSatisfaction = lazy(() => import('pages/Members/SatisfactionSurvey/SatisfactionSurvey'));
const MemberSatisfactionManage = lazy(() => import('pages/Members/SatisfactionSurvey/SatisfactionSurveyManage'));
const UrgentCareInfo = lazy(() => import('pages/Members/UrgentCareInfo/UrgentCareInfo'));
const UrgentCareInfoManage = lazy(() => import('pages/Members/UrgentCareInfo/UrgentCareInfoManage'));
const PatientRights = lazy(() => import('pages/Members/PatientRights/PatientRights'));
const PatientRightsManage = lazy(() => import('pages/Members/PatientRights/PatientRightsManage'));
const AppealGrievanceForm = lazy(() => import('pages/Members/AppealGrievanceForm/AppealGrievanceForm'));
const AppealGrievanceFormManage = lazy(() => import('pages/Members/AppealGrievanceForm/AppealGrievanceFormManage'));
const HealthCareForm = lazy(() => import('pages/Members/HealthCareForm/HealthCareForm'));
const HealthCareFormManage = lazy(() => import('pages/Members/HealthCareForm/HealthCareFormManage'));
const OtherMember = lazy(() => import('pages/Members/OtherMember/OtherMember'));
const OtherMemberManage = lazy(() => import('pages/Members/OtherMember/OtherMemberManage'));

const ContractProvidersLayout = lazy(() => import('pages/ContractProviders/ContractProvidersLayout'));
const PhysicianSurvey = lazy(() => import('pages/ContractProviders/PhysicianSurvey/PhysicianSurvey'));
const PhysicianSurveyManage = lazy(() => import('pages/ContractProviders/PhysicianSurvey/PhysicianSurveyManage'));
const ACSTMaterial = lazy(() => import('pages/ContractProviders/ACSTMaterial/ACSTMaterial'));
const ACSTMaterialManage = lazy(() => import('pages/ContractProviders/ACSTMaterial/ACSTMaterialManage'));
const FWATMaterial = lazy(() => import('pages/ContractProviders/FWATMaterial/FWATMaterial'));
const FWATMaterialManage = lazy(() => import('pages/ContractProviders/FWATMaterial/FWATMaterialManage'));
const HIPAAMaterial = lazy(() => import('pages/ContractProviders/HIPAAMaterial/HIPAAMaterial'));
const HIPAAMaterialManage = lazy(() => import('pages/ContractProviders/HIPAAMaterial/HIPAAMaterialManage'));
const AttestationForm = lazy(() => import('pages/ContractProviders/AttestationForm/AttestationForm'));
const TrainingMaterial = lazy(() => import('pages/ContractProviders/TrainingMaterial/TrainingMaterial'));
const TrainingMaterialManage = lazy(() => import('pages/ContractProviders/TrainingMaterial/TrainingMaterialManage'));
const ProviderMaterial = lazy(() => import('pages/ContractProviders/ProviderMaterial/ProviderMaterial'));
const ProviderMaterialManage = lazy(() => import('pages/ContractProviders/ProviderMaterial/ProviderMaterialManage'));
const SNPMaterial = lazy(() => import('pages/ContractProviders/SNPMaterial/SNPMaterial'));
const SNPMaterialManage = lazy(() => import('pages/ContractProviders/SNPMaterial/SNPMaterialManage'));
const MemoForm = lazy(() => import('pages/ContractProviders/MemoForm/MemoForm'));
const MemoFormManage = lazy(() => import('pages/ContractProviders/MemoForm/MemoFormManage'));
const OtherContractProvider = lazy(() => import('pages/ContractProviders/OtherContractProvider/OtherContractProvider'));
const OtherContractProviderManage = lazy(
    () => import('pages/ContractProviders/OtherContractProvider/OtherContractProviderManage'),
);

const AttestationFormReports = lazy(() => import('pages/AttestationFormReports/AttestationFormReports'));
const AttestationFormView = lazy(() => import('pages/AttestationFormReports/AttestationFormView'));

const SuperAdminLayout = lazy(() => import('pages/SuperAdmin/SuperAdminLayout'));
const Role = lazy(() => import('pages/SuperAdmin/Role/Role'));
const RoleManage = lazy(() => import('pages/SuperAdmin/Role/RoleManage'));
const RolePermission = lazy(() => import('pages/SuperAdmin/RolePermission/RolePermission'));
const User = lazy(() => import('pages/SuperAdmin/User/User'));
const UserView = lazy(() => import('pages/SuperAdmin/User/UserView'));
const UserManage = lazy(() => import('pages/SuperAdmin/User/UserManage'));
const UserPermission = lazy(() => import('pages/SuperAdmin/UserPermission/UserPermission'));
const ContnetList = lazy(() => import('pages/ManageContent/ContentList'));
const ContentForm = lazy(() => import('pages/ManageContent/ContentForm'));

const routes: IRoute[] = [
    {
        path: '/',
        element: <Home />,
        index: true,
        loginRequired: false,
    },
    {
        path: Routing.Home,
        element: <Home />,
        loginRequired: false,
    },
    {
        path: Routing.AboutUs,
        element: <AboutUs />,
        loginRequired: false,
    },
    {
        path: Routing.ContactUs,
        element: <ContactUs />,
        loginRequired: false,
    },
    {
        path: Routing.Career,
        element: <Career />,
        loginRequired: false,
    },
    {
        path: Routing.Forbidden,
        element: <Forbidden />,
        loginRequired: false,
    },
    {
        path: '',
        element: <Layout />,
        children: [
            {
                path: Routing.Resource,
                page: ApplicationModule.Useful_Links_and_Resources,
                element: <ResourceLayout />,
                loginRequired: false,
                children: [
                    {
                        path: '',
                        page: ApplicationModule.Useful_Links_and_Resources,
                        element: <Navigate to={`${Routing.UsefulLinks}`} replace />,
                        loginRequired: false,
                        index: true,
                    },
                    {
                        path: Routing.UsefulLinks,
                        page: ApplicationModule.Useful_Links_and_Resources,
                        element: <UsefulLinks />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.UsefulLinksForm,
                        page: ApplicationModule.Useful_Links_and_Resources,
                        element: <UsefulLinkManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.HealthEducation,
                        page: ApplicationModule.Useful_Links_and_Resources,
                        element: <HealthEducation />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.HealthEducationManage,
                        page: ApplicationModule.Useful_Links_and_Resources,
                        element: <HealthEducationManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.LosAngelesCounty,
                        page: ApplicationModule.Useful_Links_and_Resources,
                        element: <LosAngelesCounty />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.LosAngelesCountyManage,
                        page: ApplicationModule.Useful_Links_and_Resources,
                        element: <LosAngelesCountyManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.CDCResources,
                        page: ApplicationModule.Useful_Links_and_Resources,
                        element: <CDCResources />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.CDCResourceManage,
                        page: ApplicationModule.Useful_Links_and_Resources,
                        element: <CDCResourceManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.CultureLinguistic,
                        page: ApplicationModule.Useful_Links_and_Resources,
                        element: <CultureLinguisticResources />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.CultureLinguisticManage,
                        page: ApplicationModule.Useful_Links_and_Resources,
                        element: <CultureLinguisticResourceManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.OtherResources,
                        page: ApplicationModule.Useful_Links_and_Resources,
                        element: <OtherResources />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.OtherResourceManage,
                        page: ApplicationModule.Useful_Links_and_Resources,
                        element: <OtherResourceManage />,
                        loginRequired: false,
                    },
                ],
            },
            {
                path: Routing.Member,
                page: ApplicationModule.Members,
                element: <MembersLayout />,
                loginRequired: false,
                children: [
                    {
                        path: '',
                        page: ApplicationModule.Members,
                        element: <Navigate to={`${Routing.MemberSatisfactionSurvey}`} replace />,
                        loginRequired: false,
                        index: true,
                    },
                    {
                        path: Routing.MemberSatisfactionSurvey,
                        page: ApplicationModule.Members,
                        element: <MemberSatisfaction />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.MemberSatisfactionSurveyManage,
                        page: ApplicationModule.Members,
                        element: <MemberSatisfactionManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.UrgentCareInfo,
                        page: ApplicationModule.Members,
                        element: <UrgentCareInfo />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.UrgentCareInfoManage,
                        page: ApplicationModule.Members,
                        element: <UrgentCareInfoManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.PatientRights,
                        page: ApplicationModule.Members,
                        element: <PatientRights />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.PatientRightsManage,
                        page: ApplicationModule.Members,
                        element: <PatientRightsManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.AppealGrievance,
                        page: ApplicationModule.Members,
                        element: <AppealGrievanceForm />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.AppealGrievanceManage,
                        page: ApplicationModule.Members,
                        element: <AppealGrievanceFormManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.HealthCareOption,
                        page: ApplicationModule.Members,
                        element: <HealthCareForm />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.HealthCareOptionManage,
                        page: ApplicationModule.Members,
                        element: <HealthCareFormManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.OtherMembers,
                        page: ApplicationModule.Members,
                        element: <OtherMember />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.OtherMembersManage,
                        page: ApplicationModule.Members,
                        element: <OtherMemberManage />,
                        loginRequired: false,
                    },
                ],
            },
            {
                path: Routing.ContractProvider,
                page: ApplicationModule.Contract_Providers,
                element: <ContractProvidersLayout />,
                loginRequired: false,
                children: [
                    {
                        path: '',
                        page: ApplicationModule.Contract_Providers,
                        element: <Navigate to={`${Routing.PhysicianSatisfactionSurvey}`} replace />,
                        loginRequired: false,
                        index: true,
                    },
                    {
                        path: Routing.PhysicianSatisfactionSurvey,
                        page: ApplicationModule.Contract_Providers,
                        element: <PhysicianSurvey />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.PhysicianSatisfactionSurveyManage,
                        page: ApplicationModule.Contract_Providers,
                        element: <PhysicianSurveyManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.ACSTMaterial,
                        page: ApplicationModule.Contract_Providers,
                        element: <ACSTMaterial />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.ACSTMaterialManage,
                        page: ApplicationModule.Contract_Providers,
                        element: <ACSTMaterialManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.FWATMaterial,
                        page: ApplicationModule.Contract_Providers,
                        element: <FWATMaterial />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.FWATMaterialManage,
                        page: ApplicationModule.Contract_Providers,
                        element: <FWATMaterialManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.HIPAAMaterial,
                        page: ApplicationModule.Contract_Providers,
                        element: <HIPAAMaterial />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.HIPAAMaterialManage,
                        page: ApplicationModule.Contract_Providers,
                        element: <HIPAAMaterialManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.ProviderMaterial,
                        page: ApplicationModule.Contract_Providers,
                        element: <ProviderMaterial />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.ProviderMaterialManage,
                        page: ApplicationModule.Contract_Providers,
                        element: <ProviderMaterialManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.SnpMaterial,
                        page: ApplicationModule.Contract_Providers,
                        element: <SNPMaterial />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.SnpMaterialManage,
                        page: ApplicationModule.Contract_Providers,
                        element: <SNPMaterialManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.MemoForm,
                        page: ApplicationModule.Contract_Providers,
                        element: <MemoForm />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.MemoFormManage,
                        page: ApplicationModule.Contract_Providers,
                        element: <MemoFormManage />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.CPAttestationForm,
                        page: ApplicationModule.Contract_Providers,
                        element: <AttestationForm />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.TrainingMaterial,
                        page: ApplicationModule.Contract_Providers,
                        element: <TrainingMaterial />,
                        loginRequired: true,
                    },
                    {
                        path: Routing.TrainingMaterialManage,
                        page: ApplicationModule.Contract_Providers,
                        element: <TrainingMaterialManage />,
                        loginRequired: true,
                    },
                    {
                        path: Routing.TrainingMaterialPreview,
                        page: ApplicationModule.Contract_Providers,
                        element: <AttestationForm />,
                        loginRequired: true,
                    },
                    {
                        path: Routing.OtherContractProviders,
                        page: ApplicationModule.Contract_Providers,
                        element: <OtherContractProvider />,
                        loginRequired: false,
                    },
                    {
                        path: Routing.OtherContractProvidersManage,
                        page: ApplicationModule.Contract_Providers,
                        element: <OtherContractProviderManage />,
                        loginRequired: false,
                    },
                ],
            },
            {
                path: Routing.AttestationForms,
                page: ApplicationModule.Attestation_Form_Reports,
                element: <AttestationFormReports />,
                loginRequired: true,
            },
            {
                path: Routing.AttestationFormView,
                page: ApplicationModule.Attestation_Form_Reports,
                element: <AttestationFormView />,
                loginRequired: true,
            },
            {
                path: Routing.SuperAdmin,
                page: ApplicationModule.Super_Admin,
                element: <SuperAdminLayout />,
                loginRequired: true,
                children: [
                    {
                        path: '',
                        page: ApplicationModule.Super_Admin,
                        element: <Navigate to={`${Routing.Role}`} replace />,
                        loginRequired: false,
                        index: true,
                    },
                    {
                        path: Routing.Role,
                        page: ApplicationModule.Super_Admin,
                        element: <Role />,
                        loginRequired: true,
                    },
                    {
                        path: Routing.RoleManage,
                        page: ApplicationModule.Super_Admin,
                        element: <RoleManage />,
                        loginRequired: true,
                    },
                    {
                        path: Routing.RolePermission,
                        page: ApplicationModule.Super_Admin,
                        element: <RolePermission />,
                        loginRequired: true,
                    },
                    {
                        path: Routing.User,
                        page: ApplicationModule.Super_Admin,
                        element: <User />,
                        loginRequired: true,
                    },
                    {
                        path: Routing.UserView,
                        page: ApplicationModule.Super_Admin,
                        element: <UserView />,
                        loginRequired: true,
                    },
                    {
                        path: Routing.UserManage,
                        page: ApplicationModule.Super_Admin,
                        element: <UserManage />,
                        loginRequired: true,
                    },
                    {
                        path: Routing.UserPermission,
                        page: ApplicationModule.Super_Admin,
                        element: <UserPermission />,
                        loginRequired: true,
                    },
                ],
            },
            {
                path: Routing.ContentList,
                page: ApplicationModule.Manage_Content,
                element: <ContnetList />,
                loginRequired: true,
            },
            {
                path: Routing.ContentForm,
                page: ApplicationModule.Manage_Content,
                element: <ContentForm />,
                loginRequired: true,
            },
        ],
    },
];

const AppRouting: React.FC = () => {
    const userData = useSelector((state: IApplicationState) => state?.AuthData);
    const ability = userData?.abilities;

    const checkRoute = React.useCallback(
        (action, page) => {
            return ability?.find((x) => x.subject === page)?.action?.includes(action);
        },
        [ability],
    );

    const createRoute = React.useCallback(
        (route: IRoute, index, action = CAN_VIEW) => {
            return (
                <>
                    {route?.index ? (
                        <Route
                            key={`${route?.path}_${index}`}
                            index={true}
                            path={route?.path}
                            element={
                                (checkRoute(action, route?.page) && route?.page) || !route?.loginRequired ? (
                                    route?.element
                                ) : (
                                    <Navigate to={`../../../${Routing.Forbidden}`} replace={true} />
                                )
                            }
                        />
                    ) : (
                        <Route
                            key={`${route?.path}_${index}`}
                            path={route?.path}
                            element={
                                (checkRoute(action, route?.page) && route?.page) || !route?.loginRequired ? (
                                    route?.element
                                ) : (
                                    <Navigate to={`../../../${Routing.Forbidden}`} replace={true} />
                                )
                            }
                        >
                            {route?.children?.length > 0 &&
                                route?.children?.map((child: IRoute, index) => createRoute(child, index))}
                        </Route>
                    )}
                </>
            );
        },
        [ability],
    );
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    {routes?.map((route, index) => createRoute(route, index))}
                    <Route path="*" element={<Home />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouting;
