import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { createMongoAbility } from '@casl/ability';
import { IApplicationState } from 'store/state/app-state';
import store from 'store';

const storeData: IApplicationState = store.getState();
const abilities = storeData.AuthData ? storeData?.AuthData?.abilities : [];

const AbilityContext = createContext(null);
const Can = createContextualCan(AbilityContext.Consumer);
const ability = createMongoAbility(abilities);

export { AbilityContext, Can };
export default ability;
