export const RegularExpression = {
    Email: new RegExp(
        // eslint-disable-next-line no-useless-escape
        /^(?=.{1,64}@)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(?=.{1,255}$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
    ),
    Password: new RegExp(/^(?!.* )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[@#!$%&_-]).{8,16}$/),
    Phone: new RegExp(/^\([0-9]{3}\)( |-)?[0-9]{3}( |-)?[0-9]{4}$/),
    Unmask: new RegExp(/[^\d]/g),
    Link: new RegExp(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/?[a-zA-Z0-9#]+\/?)*$/),
};
