export default {
    PhoneIcon: require('./ic-call.svg').default,
    MailIcon: require('./ic-mail.svg').default,
    FacebookIcon: require('./ic-fb.svg').default,
    TwitterIcon: require('./ic-twitter.svg').default,
    InstagramIcon: require('./ic-instagram.svg').default,
    LinkedinIcon: require('./ic-linkedin.svg').default,
    YoutubeIcon: require('./ic-youtube.svg').default,
    Logo: require('./logo.png'),
    DoctorBg: require('./doctor-bg.png'),
    DoctorBg2: require('./home-thumb.png'),
    DesignBgOne: require('./design-right.svg').default,
    DesignBgTwo: require('./design-left.svg').default,
    passwordShow: require('./ic-eye-show.svg').default,
    passwordHide: require('./ic-eye-hide.svg').default,
    checkboxChecked: require('./checkbox-checked.svg').default,
    checkboxUnchecked: require('./checkbox.svg').default,
    DoctorTeam: require('./doctor-team.png'),
    receptionist: require('./receptionist.png'),
    doctorMeeting: require('./doctor-meeting.png'),
    profile: require('./ic-profile.svg').default,
    logout: require('./ic-logout.svg').default,
    date: require('./ic-date.svg').default,
    sorting: require('./ic-sorting.svg').default,
    close: require('./ic-close.svg').default,
    PDF: require('./pdf-demo.png'),
    Print: require('./ic-print.svg').default,
    PDFBig: require('./pdf-big.png'),
    SelectArrow: require('./ic-select-arrow.svg').default,
    ContractPDF: require('./contract-print.png'),
    Dashboard: require('./dashboard.svg').default,
    RecycleBin: require('./recycle-bin.svg').default,
    ChangePassword: require('./change-password.svg').default,
};
