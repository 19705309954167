export enum ApplicationModule {
    // Home = 'Home',
    // AboutUs = 'AboutUs',
    // ContactUs = 'ContactUs',
    // Career = 'Career',
    Useful_Links_and_Resources = 'Useful Links and Resources',
    Members = 'Members',
    Contract_Providers = 'Contract Providers',
    Attestation_Form_Reports = 'Attestation Form Reports',
    Super_Admin = 'Super Admin',
    Manage_Content = 'Manage Content',
}
