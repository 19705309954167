import { Container } from '@mui/system';
import images from 'assets/images';
import { ISettingModel } from 'models/settings';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import settingService from 'services/settings/setting-service';
import messages from 'shared/constants/messages';
import { SettingNames } from 'shared/enums/settings';
import { getSettingValue } from 'shared/utils/commonFunctions';

const TopHeader: React.FC = () => {
    const [settings, setSettings] = React.useState<Array<ISettingModel>>();

    React.useEffect(() => {
        (async function () {
            await getSettingsByName();
        })();
    }, []);

    const getSettingsByName = async () => {
        await settingService
            .getSettingsByName(`${SettingNames.CMS_CONTACT_US_EMAIL},${SettingNames.CMS_CONTACT_US_MOBILE}`)
            .then((response) => {
                const { data } = response.data;
                setSettings(data);
            })
            .catch(() => toast.error(messages.SomethingWentWrong));
    };

    return (
        <div className="header_top">
            <Container maxWidth="xl">
                {settings?.length > 0 && (
                    <ul className="header_top_left">
                        <li>
                            <Link to={`tel:${getSettingValue(settings, SettingNames.CMS_CONTACT_US_MOBILE)}`}>
                                <em className="header-icon">
                                    <img src={images.PhoneIcon} alt="phone" />
                                </em>
                                {getSettingValue(settings, SettingNames.CMS_CONTACT_US_MOBILE)}
                            </Link>
                        </li>
                        <li>
                            <Link to={`mailto:${getSettingValue(settings, SettingNames.CMS_CONTACT_US_EMAIL)}`}>
                                <em className="header-icon">
                                    <img src={images.MailIcon} alt="mail" />
                                </em>
                                {getSettingValue(settings, SettingNames.CMS_CONTACT_US_EMAIL)}
                            </Link>
                        </li>
                    </ul>
                )}
                {/* <ul className="header_top_right">
                    <li>
                        <Link
                            to={getSettingValue(settings, SettingNames.CMS_FACEBOOK_URL)}
                            target="_blank"
                            title="Facebook"
                        >
                            <IconButton size="small">
                                <img src={images.FacebookIcon} alt="fb" />
                            </IconButton>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={getSettingValue(settings, SettingNames.CMS_TWITTER_URL)}
                            target="_blank"
                            title="Twitter"
                        >
                            <IconButton size="small">
                                <img src={images.TwitterIcon} alt="twitter" />
                            </IconButton>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={getSettingValue(settings, SettingNames.CMS_INSTAGRAM_URL)}
                            target="_blank"
                            title="Instagram"
                        >
                            <IconButton size="small">
                                <img src={images.InstagramIcon} alt="insta" />
                            </IconButton>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={getSettingValue(settings, SettingNames.CMS_LINKEDIN_URL)}
                            target="_blank"
                            title="Linkedin"
                        >
                            <IconButton size="small">
                                <img src={images.LinkedinIcon} alt="linkedin" />
                            </IconButton>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={getSettingValue(settings, SettingNames.CMS_YOUTUBE_URL)}
                            target="_blank"
                            title="Youtube"
                        >
                            <IconButton size="small">
                                <img src={images.YoutubeIcon} alt="youtube" />
                            </IconButton>
                        </Link>
                    </li>
                </ul> */}
            </Container>
        </div>
    );
};

export default TopHeader;
