import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { env } from 'env';
import { toast } from 'react-toastify';
import messages from 'shared/constants/messages';
import { Routing } from 'shared/constants/routing';
import { HttpStatusCode } from 'shared/enums/http-status-code';
import store from 'store';
import { AuthActionType } from 'store/action-types/auth-action-type';
import { logout } from 'store/actions/auth-action';
import { IApplicationState } from 'store/state/app-state';
import { getBaseURL, showLoader, hideLoader } from 'shared/utils/commonFunctions';

// a request interceptor

axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        const storeData: IApplicationState = store.getState();

        if (config.url) {
            config.url = getBaseURL(env.REACT_APP_BASE_URL) + config.url;
        }
        config = {
            ...config,
            headers: {
                ...config.headers,
                Authorization: `bearer ${storeData?.AuthData?.authConfigs?.accessToken}`,
            } as AxiosRequestHeaders,
        };
        if (storeData?.GeneralData?.showLoader) showLoader();
        else store.dispatch({ type: AuthActionType.SHOW_LOADER, payload: true });

        return config;
    },
    (error: AxiosError) => {
        hideLoader();
        if (error.toString()?.includes(messages.NetworkError)) {
            // toast.error(messages.InternetConnectionError);
            return;
        }
        switch (error?.response?.status) {
            case HttpStatusCode.BadRequest:
            case HttpStatusCode.ConflictError:
            case HttpStatusCode.InternalServerError:
                toast.error(messages.InternalServerError);
                return;
        }
        return Promise.reject(messages.SomethingWentWrong);
    },
);

// a response interceptor
axios.interceptors.response.use(
    (response: AxiosResponse) => {
        hideLoader();
        switch (response.data?.statusCode) {
            case HttpStatusCode.Error:
                toast.error(response?.data?.message);
                break;
            case HttpStatusCode.Forbidden:
                window.location.href = `${Routing.Forbidden}`;
                return;
        }
        return response;
    },
    (error: AxiosError) => {
        hideLoader();

        if (error.toString()?.includes(messages.NetworkError)) {
            // toast.error(messages.InternetConnectionError);
            return;
        }

        switch (error.response?.status) {
            case HttpStatusCode.BadRequest:
            case HttpStatusCode.ConflictError:
            case HttpStatusCode.InternalServerError:
                toast.error(messages.InternalServerError);
                return;
            case HttpStatusCode.Unauthorized:
                store.dispatch(logout());
                return;
        }

        return Promise.reject(messages.SomethingWentWrong);
    },
);

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
};
