import { Typography, DialogContent, Button } from '@mui/material';
import { Formik, Field } from 'formik';
import React from 'react';
import { FormikInput } from 'shared/material-ui-formik';
import authService from 'services/auth/auth-service';
import { toast } from 'react-toastify';
import messages from 'shared/constants/messages';
import { HttpStatusCode } from 'shared/enums/http-status-code';
import { ForgotPasswordValidationSchema } from 'validation/account/forgot-password-validation';
import { IForgotPasswordModel } from 'models/account';

interface IForgotPasswordFormProps {
    handleDialogClose: () => void;
}

const ForgotPasswordForm: React.FC<IForgotPasswordFormProps> = ({ handleDialogClose }) => {
    const initialState: IForgotPasswordModel = {
        email: '',
    };

    const handleSubmit = async (data: IForgotPasswordModel) => {
        await authService
            .forgotPassword(data?.email)
            .then((response) => {
                const { message, statusCode } = response.data;
                if (statusCode === HttpStatusCode.Success) {
                    toast.success(messages.ResetPasswordLinkSuccess);
                    handleDialogClose();
                } else if (statusCode === HttpStatusCode.UserNotFound) toast.error(message);
            })
            .catch(() => toast.error(messages.SomethingWentWrong));
    };

    return (
        <>
            <div className="MuiDialogTitle-root">
                <Typography variant="h2">Forgot Password</Typography>
            </div>
            <DialogContent>
                <Formik
                    initialValues={initialState}
                    onSubmit={handleSubmit}
                    validationSchema={ForgotPasswordValidationSchema}
                    validateOnBlur={false}
                    validateOnChange={true}
                >
                    {(props) => {
                        const { handleSubmit } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="form-label">Email</label>
                                    <Field name="email" id="email" type="text" component={FormikInput} />
                                </div>
                                <Button color="primary" type="submit" variant="contained" fullWidth title="Submit">
                                    Submit
                                </Button>
                            </form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </>
    );
};

export default ForgotPasswordForm;
