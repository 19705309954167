import 'date-fns';
import React from 'react';
import _ from 'lodash';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormHelperText } from '@mui/material';
import images from 'assets/images';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import { DateWithoutTime } from 'shared/utils/dateFormat';

function dateImage() {
    return <img src={images.date} alt="date" />;
}

const FormikDatePicker = ({ field: { ...fields }, form: { touched, errors }, ...props }): React.ReactNode => {
    const { hasObject = false, minDate, maxDate, value, disabled, onChange, isForFilter = true } = props;
    const error = Boolean(_.get(touched, fields?.name) && _.get(errors, fields?.name));
    const getError = () => {
        let errorString = errors;
        if (hasObject) fields?.name?.split('.').map((name) => (errorString = errorString[name]));
        return errorString;
    };

    const handleChange = (newValue: Dayjs | null) => {
        let newDateValue: Date = null;
        if (newValue) {
            newDateValue = new Date(newValue?.toDate());
            newDateValue = new Date(`${newValue?.toDate()?.toDateString()} ${new Date()?.toTimeString()}`);
        }
        if ((newDateValue && !isNaN(Date.parse(newDateValue?.toString()))) || !isForFilter)
            onChange(DateWithoutTime(newDateValue));
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DesktopDatePicker']}>
                    <DesktopDatePicker
                        slots={{
                            openPickerIcon: dateImage,
                        }}
                        minDate={minDate ? dayjs(minDate) : null}
                        maxDate={maxDate ? dayjs(maxDate) : null}
                        value={value ? dayjs(value) : null}
                        format="MM/DD/YYYY"
                        views={['year', 'month', 'day']}
                        disabled={disabled}
                        onChange={handleChange}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: error,
                                className: 'datepicker-input',
                                spellCheck: false,
                            },
                        }}
                    />
                </DemoContainer>
            </LocalizationProvider>
            {error && <FormHelperText error>{error && (hasObject ? getError() : errors[fields?.name])}</FormHelperText>}
        </>
    );
};

export default FormikDatePicker;
