export default {
    RecordAddSuccessMsg: 'Record added successfully.',
    RecordDeleteSuccessMsg: 'Record deleted successfully.',
    RecordEditSuccessMsg: 'Record updated successfully.',
    RecordRestoreSuccessMsg: 'Record restored successfully.',
    SomethingWentWrong: 'Something went wrong. Please try again.',
    InternalServerError: 'Internal server error.',
    InternetConnectionError: 'We are not able to serve you at the moment, please try again after some time.',
    NetworkError: 'Network Error',
    ForbiddenError: `You don't have permission to access this page or resource.`,
    NoRecordsFound: 'No records found.',
    LogoutConfirm: 'Are you sure, you want to logout?',
    FileNotFoundMsg: 'Document does not exists.',
    InvalidFileFormatMsg: 'Please select ##fileFormat## file.',
    InvalidFileSizeMsg: 'File size should be less than 5Mb.',
    DeleteConfirm: 'Are you sure you want to DELETE ?',
    RestoreConfirm: 'Are you sure you want to Restore ?',
    CancelConfirm: 'Are you sure you want to cancel? All information will be lost.',
    SubmitPrintConfirmMsg1: 'Thank you very much for taking your time to meet the compliance.',
    SubmitPrintConfirmMsg2:
        'Please press the "Submit to PHMSO and Print" button to submit this form to PHMSO and print this form out for your copy',
    DocumentRequiredWarnMsg: 'Document is required.',
    RoleInUseMsg: 'One or more roles are already in use.',
    DuplicateAttestationFormMsg: 'Attestation form with the same TaxId and NPI already exists for the current year.',
    RolePermissionUpdateSuccess: 'Role permissions updated successfully.',
    CompulsoryForWriteMsg: 'Permission "Read" is compulsory for "Write" permission.',
    CompulsoryForEditMsg: 'Permission "Read" is compulsory for "Edit" permission.',
    CompulsoryForDeleteMsg: 'Permission "Read" is compulsory for "Delete" permission.',
    DuplicateRoleMsg: 'Another role with this description already exists.',
    DuplicateUserMsg: 'Another user with this email or user ID already exists.',
    UserPermissionUpdateSuccess: 'User permissions updated successfully.',
    CurrentLoggedInUserMsg: 'You can not delete the current logged-in user.',
    TrainingMaterialRequired: 'Please select atlease one training material.',
    PasswordChangeSuccess: 'Password changed successfully.',
    ResetPasswordLinkSuccess: 'Reset password link has been successfully sent.',
};
