import * as Yup from 'yup';

export const ResetPasswordValidationSchema = () => {
    return Yup.object().shape({
        newPassword: Yup.string()
            .required('New password is a required field')
            .min(6, 'New password must be greater than 6 letters')
            .max(16, 'New password must be less than 16 letters')
            .test('blankSpace', 'New password is a required field', (value) => {
                return !!value?.trim();
            }),
        confirmNewPassword: Yup.string()
            .required('Confirm New Password is a required field')
            .oneOf([Yup.ref('newPassword')], 'New Password and Confirm New Password must match.'),
    });
};
