export enum Digits {
    MinusOne = -1,
    Zero = 0,
    One = 1,
    Two = 2,
    Three = 3,
    Four = 4,
    Five = 5,
    Six = 6,
    Seven = 7,
    Eight = 8,
    Nine = 9,
    Ten = 10,
    Fifteen = 15,
    Hundred = 100,
    Thousand = 1000,
    TenThousand = 10000,
}
