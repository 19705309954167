import { Button, Dialog, DialogActions, DialogContent, IconButton, Typography, Breakpoint } from '@mui/material';
import images from 'assets/images';
import React from 'react';

interface IConfirmDialogProps {
    openDialog?: boolean;
    title?: string;
    message?: string;
    succssBtnLabel?: string;
    outlinedBtnLabel?: string;
    maxWidth?: Breakpoint | false;
    titleClassName?: string;
    handleDialogClose?: () => void;
    handleSuccess?: () => void;
}

const ConfirmDialog: React.FC<IConfirmDialogProps> = ({
    openDialog,
    title,
    message,
    maxWidth,
    succssBtnLabel = 'Confirm',
    outlinedBtnLabel = 'Cancel',
    handleSuccess,
    handleDialogClose,
}) => {
    return (
        <Dialog
            open={openDialog}
            scroll="paper"
            className="confirmation-dialog"
            maxWidth={maxWidth}
            onClose={handleDialogClose}
        >
            <div className="dialog-header">
                <Typography variant="h3">{title}</Typography>
                <IconButton size="small" onClick={handleDialogClose}>
                    <img src={images.close} alt="close" />
                </IconButton>
            </div>
            <DialogContent>
                <p className="confirm-message">{message}</p>
            </DialogContent>
            <DialogActions className="dialog-footer">
                {succssBtnLabel && (
                    <Button variant="contained" color="primary" onClick={handleSuccess} title={succssBtnLabel}>
                        {succssBtnLabel}
                    </Button>
                )}
                <Button
                    className="ml-0"
                    variant="outlined"
                    color="primary"
                    onClick={handleDialogClose}
                    title={outlinedBtnLabel}
                >
                    {outlinedBtnLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
