import { RegularExpression } from 'shared/constants/regular-expression';
import * as Yup from 'yup';

export const ForgotPasswordValidationSchema = () => {
    return Yup.object().shape({
        email: Yup.string()
            .required('Email is a required field')
            .max(200, 'Email must be less than 200 letters')
            .matches(RegularExpression.Email, 'Please enter a valid email')
            .test('blankSpace', 'Email is a required field', (value) => {
                return !!value?.trim();
            }),
    });
};
