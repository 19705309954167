import { AxiosResponse } from 'axios';
import { IChangePasswordModel, ILoginModel, ILoginResponseModel } from 'models/account';
import { ApiResponseModel } from 'models/api';
import baseService from 'services/base-service';

const endPointBaseURL = `auth`;

const login = async (
    requestBody: ILoginModel,
): Promise<AxiosResponse<ApiResponseModel<ILoginResponseModel | string>>> =>
    baseService.post<ApiResponseModel<ILoginResponseModel | string>>(`${endPointBaseURL}/login`, requestBody);

const changePassword = async (
    model: IChangePasswordModel,
    isFirstLogin = false,
): Promise<AxiosResponse<ApiResponseModel<null>>> =>
    baseService.put<ApiResponseModel<null>>(
        `${endPointBaseURL}/change-password?userIdEnc=${model.userId}&&oldPassword=${model.currentPassword}&&newPassword=${model.newPassword}&&isFirstLogin=${isFirstLogin}`,
    );

const forgotPassword = async (email?: string): Promise<AxiosResponse<ApiResponseModel<null>>> =>
    baseService.get<ApiResponseModel<null>>(`${endPointBaseURL}/forgot-password?email=${email}`);

const resetPassword = async (model): Promise<AxiosResponse<ApiResponseModel<null>>> =>
    baseService.post<ApiResponseModel<null>>(`${endPointBaseURL}/reset-password`, model);

export default { login, changePassword, forgotPassword, resetPassword };
