import React from 'react';
import { Outlet } from 'react-router-dom';
import SecureHeader from './SecureHeader';
import Footer from '../ui-layout/Footer';

const SecureLayout: React.FC = () => {
    // Humbugger Menu JS
    const handleMobileClick = () => document.body.classList.remove('menu-open');

    return (
        <>
            <div className="wrapper secondary-wrapper">
                <div className="wrapper-bg" onClick={handleMobileClick}></div>
                <SecureHeader />
                <main className="main-content">
                    <Outlet />
                </main>
            </div>
            <Footer />
        </>
    );
};

export default SecureLayout;
