export enum SettingGroupIds {
    CMS = 1,
}

export enum SettingNames {
    CMS_HOME_PAGE = 'CMS_HOME_PAGE',
    CMS_ABOUT_US = 'CMS_ABOUT_US',
    CMS_CONTACT_US = 'CMS_CONTACT_US',
    CMS_CONTACT_US_MOBILE = 'CMS_CONTACT_US_MOBILE',
    CMS_CONTACT_US_EMAIL = 'CMS_CONTACT_US_EMAIL',
    CMS_CAREER_OPPORTUNITIES_EMAIL = 'CMS_CAREER_OPPORTUNITIES_EMAIL',
    CMS_CAREER_OPPORTUNITIES_MOBILE = 'CMS_CAREER_OPPORTUNITIES_MOBILE',
    CMS_INSTAGRAM_URL = 'CMS_INSTAGRAM_URL',
    CMS_LINKEDIN_URL = 'CMS_LINKEDIN_URL',
    CMS_TWITTER_URL = 'CMS_TWITTER_URL',
    CMS_YOUTUBE_URL = 'CMS_YOUTUBE_URL',
    CMS_FACEBOOK_URL = 'CMS_FACEBOOK_URL',
    PROVIDER_LOGIN_URL = 'PROVIDER_LOGIN_URL',
    ATTESTATION_HEADER_ADDRESS = 'ATTESTATION_HEADER_ADDRESS',
    ATTESTATION_HEADER_COMPANY_NAME = 'ATTESTATION_HEADER_COMPANY_NAME',
    ATTESTATION_HEADER_FAX = 'ATTESTATION_HEADER_FAX',
    ATTESTATION_HEADER_PHONE = 'ATTESTATION_HEADER_PHONE',
}

export enum SettingFieldType {
    TextArea = 'TextArea',
    Email = 'Email',
    PhoneNumber = 'PhoneNumber',
    URL = 'URL',
}
