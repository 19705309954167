import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import PublicHeader from './PublicHeader';

const PublicLayout: React.FC = () => {
    const handleMobileClick = () => document.body.classList.remove('menu-open');

    return (
        <>
            <div className="wrapper">
                <div className="wrapper-bg" onClick={handleMobileClick}></div>
                <PublicHeader />
                <main className="main-content">
                    <Outlet />
                </main>
            </div>
            <Footer />
        </>
    );
};

export default PublicLayout;
